<template>
  <div class="flex xl:flex-col md:flex-col lg:flex-col md:flex-col sm:flex-col gap-x-8 gap-y-8 items-center justify-center">
      <!-- BarPot Trader --->
      <div class="p-4 md:p-8 lg:py-12 md:transform bg-white rounded-lg md:shadow-md md:hover:shadow-xl md:transition-all md:duration-500 border-2 md:border border-indigo-100">      
        <span class="flex absolute h-4 w-4 top-0 right-0 -mt-1 -mr-1">
          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
          <span class="relative inline-flex rounded-full h-4 w-4 bg-sky-500"></span>
        </span>
        <div class="flex">
          <span class="bg-red-100 text-red-800 text-lg font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900"><span>Most Popular 🎖</span></span>
        </div>
        <div class="flex justify-between items-center mb-4 ">
          <h4 class="text-xl lg:text-2xl font-bold">Trader</h4>
          <span class="flex flex-col text-xl lg:text-2xl font-bold">
            <span class="text-xl lg:text-2xl font-bold">&dollar;120/Month</span>
          </span>
        </div>
        <div class="flex mb-6">
          <p class="text-sm italic text-[#8c98a4]">Monthly Pricing / Cancel any time /</p>
          <p class="ml-1 text-sm italic text-[#8c98a4]">Upgrade any time</p>
        </div>
        <ul class="text-gray-600 space-y-4 text-lg">
          <li class="flex space-x-2 border-b border-t border-dashed items-center py-2">
            <div class="w-6 h-6 mr-4">
              <svg class="text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M5 13l4 4L19 7"></path></svg>
            </div>
            <span class="bg-green-100 text-black-800 text-lg font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900"><span> Everything in <span class="underline font-bold">Free</span>, and...</span></span>
          </li>
          <li class="flex items-center border-b border-solid pb-4">
            <div class="w-6 h-6 mr-4">
              <svg class="text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M5 13l4 4L19 7"></path></svg>
            </div>
            <span>Access to <span class="underline font-bold">Momentum Trader</span> <span class="text-sm	italic">(8/Max Strategies)</span></span>
          </li>
          <li class="flex items-center border-b border-solid pb-4">
            <div class="w-6 h-6 mr-4">
              <svg class="text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M5 13l4 4L19 7"></path></svg>
            </div>
            <span><span class="underline font-bold">60</span><span class="text-sm	italic">/day</span> Historical Data on Individual Stock Charts</span>
          </li>
          <li class="flex items-center border-b border-solid pb-4">
            <div class="w-6 h-6 mr-4">
              <svg class="text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M5 13l4 4L19 7"></path></svg>
            </div>
            <span>Unlimited Access to Individual Stock Analyses</span>
          </li>
          <li class="flex items-center border-b border-solid pb-4">
            <div class="w-6 h-6 mr-4">
              <svg class="text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M5 13l4 4L19 7"></path></svg>
            </div>
            <span>Automated Paper Trading on your Strategies</span>
          </li>
          <li class="flex items-center border-b border-solid pb-4">
            <div class="w-6 h-6 mr-4">
              <svg class="text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M5 13l4 4L19 7"></path></svg>
            </div>
            <span>Real-time Strategy Metrics</span>
          </li>
          <li class="flex items-center border-b border-solid pb-4">
            <div class="w-6 h-6 mr-4">
              <svg class="text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M5 13l4 4L19 7"></path></svg>
            </div>
            <span>Premier Customer Chat & Email Support</span>
          </li>
        </ul>
        <slot />
      </div>
    </div>
</template>

<script>
export default {
 props: {
 },
 mounted(){
 },
 data(){
    return {
    }
 },
 methods: {

 }
 
}
</script>

<style scoped>

</style>