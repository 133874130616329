<template>
  <div class="flex flex-row w-[auto] 2xl:w-[auto] xl:w-[auto] lg:w-[94%] justify-start 2xl:justify-start xl:justify-start lg:justify-center p-6 mb-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    <div class="flex 2xl:flex-row xl:flex-row lg:flex-col sm:flex-col">
      <div class="flex flex-col">
        <h5 data-tooltip-target="simulated-return-tooltip" class="ml-2 mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Simulated Returns</h5>
        <div id="simulated-return-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
          Hypothetical investment performance created through modeling, used for strategy testing and risk assessment without actual capital involvement.
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
        <div class="flex 2xl:flex-col xl:flex-col lg:flex-row lg:divide-y">
          <div class="flex flex-col px-2">
            <a href="#" class="flex flex-row items-center" data-tooltip-target="initial-value-tooltip">
                <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Current Strategy Value</h5>
                <span class="material-symbols-outlined text-sm mx-1">info</span>
            </a>
            <div id="initial-value-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
              Current cash amount of this strategy, serving as a reference for measurements from your initial invested value to the current Strategy value.
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
            <p :class="store.xhrDataPortfolioItem?.investment?.trade_journal?.total_strategy_value > store.xhrDataPortfolioItem?.investment?.configuration?.initial_amount ? 'w-fit mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white bg-green-300': 'w-fit mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white bg-red-300'">${{ new Number(store.xhrDataPortfolioItem?.investment?.trade_journal?.total_strategy_value).toLocaleString()}}</p>
          </div>

          <div class="flex flex-col px-2">
            <a href="#" class="flex flex-row items-center" data-tooltip-target="initial-value-tooltip">
                <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Initial Invested Value</h5>
                <span class="material-symbols-outlined text-sm mx-1">info</span>
            </a>
            <div id="initial-value-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
              Starting cash amount you invested into this strategy, serving as a reference for measurements during active trading.
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
            <p class="mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white">${{ new Number(store.xhrDataPortfolioItem?.investment?.configuration?.initial_amount).toLocaleString()}}</p>
          </div>
          
          <div class="flex flex-col px-2">
            <a href="#" class="flex flex-row items-center" data-tooltip-target="max-drawdown-percent-tooltip">
                <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Max Drawdown</h5>
                <span class="material-symbols-outlined text-sm mx-1">info</span>
            </a>
            <div id="max-drawdown-percent-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
              The maximum percent loss or decline in value experienced by an investment or portfolio from its peak to its lowest point.
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
            <p class="mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white">{{ new Number(store.xhrDataPortfolioItem?.investment?.trade_journal?.drawdown?.max_drawdown_pct).toLocaleString()}}%</p>
          </div>

          <!-- <div class="flex flex-col px-2">
            <a href="#" class="flex flex-row items-center" data-tooltip-target="max-drawdown-value-tooltip">
                <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Max Drawdown Value</h5>
                <span class="material-symbols-outlined text-sm mx-1">info</span>
            </a>
            <div id="max-drawdown-value-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
              The maximum value loss or decline in value experienced by an investment or portfolio from its peak to its lowest point.
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
            <p class="mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white">{{ new Number(store.xhrDataPortfolioItem?.simulation?.calculated_metrics?.drawdown?.max_drawdown).toLocaleString()}}</p>
          </div> -->
        </div>
      </div>
      <hr class="h-full border-solid border border-gray mx-4" style="height: inherit;">
      <div class="flex flex-col">
        <h5 class="ml-2 mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Metrics</h5>
        <div class="flex flex-row divide-x 2xl:flex-row xl:flex-row lg:flex-col sm:flex-col">
          <div class="flex flex-col px-2 w-48">
            <a href="#" class="flex flex-row items-center" data-tooltip-target="cumulative-return-tooltip">
                <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Cumulative Return %</h5>
                <span class="material-symbols-outlined text-sm mx-1">info</span>
            </a>
            <div id="cumulative-return-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
              The total percentage change in the value of your portfolio over a specified period, including all gains and losses.
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
            <p :class="store.xhrDataPortfolioItem?.simulation?.calculated_metrics?.cumulative_return_pct > 0 ? 'w-fit mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white bg-green-300': 'w-fit mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white bg-red-300'">
              {{ new Number(store.xhrDataPortfolioItem?.investment?.trade_journal?.total_percent_return_cumulative).toLocaleString()}}%</p>
          </div>
          
          <div class="flex flex-col px-2 w-48">
            <a href="#" class="flex flex-row items-center" data-tooltip-target="average-winrate-tooltip">
                <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Avg. Trade Return %</h5>
                <span class="material-symbols-outlined text-sm mx-1">info</span>
            </a>
            <div id="average-winrate-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
              The average success rate of individualized models within your portfolio that achieves their goals or outcomes.
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
            <p class="mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white">
              {{ new Number(store.xhrDataPortfolioItem?.investment?.trade_journal?.rates?.average_return).toLocaleString()}}%</p>
          </div>
        </div>

        <div class="flex flex-row divide-x 2xl:flex-row xl:flex-row lg:flex-col sm:flex-col">
          <div class="flex flex-col px-2 w-48">
            <a href="#" class="flex flex-row items-center" data-tooltip-target="average-confidence-tooltip">
                <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Avg. Trade Return % (Losses)</h5>
                <span class="material-symbols-outlined text-sm mx-1">info</span>
            </a>
            <div id="average-confidence-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
              The average level of certainty/trustworthiness exhibited by individualized models attempting to achieve their predictions.
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
            <p class="mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white">
              {{ new Number(store.xhrDataPortfolioItem?.investment?.trade_journal?.rates?.average_return_loss).toLocaleString()}}%</p>
          </div>

          <div class="flex flex-col px-2 w-48">
            <a href="#" class="flex flex-row items-center" data-tooltip-target="cumulative-value-tooltip">
                <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Avg. Trade Return % (Wins)</h5>
                <span class="material-symbols-outlined text-sm mx-1">info</span>
            </a>
            <div id="cumulative-value-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">            
              The total value of an portfolio over the simulated time, including all gains and losses.
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
            <p :class="store.xhrDataPortfolioItem?.simulation?.calculated_metrics?.cumulative_return_pct > 0 ? 'w-fit mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white bg-green-300': 'w-fit mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white bg-red-300'">
              {{ new Number(store.xhrDataPortfolioItem?.investment?.trade_journal?.rates?.average_return_win).toLocaleString()}}%</p>
          </div>
        </div>
      </div>
      <div class="flex flex-row divide-x sm:mt-4">
          <div class="flex bg-white rounded-lg shadow dark:bg-gray-800 p-2 md:p-4 2xl:flex-row xl:flex-row lg:flex-col sm:flex-col 2xl:bg-white xl:bg-white lg:shadow 2xl:shadow xl:shadow lg:shadow sm:bg-transparent sm:shadow-none">
            <div class="flex flex-col">
              <div class="flex flex-col px-2 w-max">
                <a href="#" class="flex flex-row items-center" data-tooltip-target="win-rate-tooltip">
                    <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Win Rate</h5>
                    <span class="material-symbols-outlined text-sm mx-1">info</span>
                </a>
                <div id="win-rate-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                  The percentage of successful wins (to losses) in your current portfolio.
                  <div class="tooltip-arrow" data-popper-arrow></div>
                </div>
                <p class="mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white">
                  {{ new Number(store.xhrDataPortfolioItem?.investment?.trade_journal?.rates?.rate).toFixed(2).toLocaleString()}}%</p>
              </div>

              <div class="flex flex-col px-2 w-max">
                <a href="#" class="flex flex-row items-center" data-tooltip-target="total-trade-tooltip">
                    <div class="flex flex-col">
                      <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Total Trades</h5>
                    </div>
                    <span class="material-symbols-outlined text-sm mx-1">info</span>
                </a>
                <div id="total-trade-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                  The overall count of trades executed within your selected timeframe for your portfolio.
                  <div class="tooltip-arrow" data-popper-arrow></div>
                </div>
                <p class="mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white">
                  {{ new Number(store.xhrDataPortfolioItem?.investment?.trade_journal?.rates?.total_trades).toLocaleString()}}</p>
              </div>
            </div>

            <!-- Line Chart -->
            <div class="h-full w-full 2xl:min-w-[20rem] xl:min-w-[20rem] lg:min-w-[20rem] sm:min-w-[15rem]" id="tab-paper-info-metric-donut-chart"></div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {store} from '../stores/PageBotTraderInstanceStore'

export default {
 props: {
  defaultData: Object,
  respTimePeriodConfig: Object,
 },
 components: {
  },
 data(){
     return {store}
 },
 watch: {
  'store.xhrDataPortfolioItem': function() {
    if (store.xhrDataPortfolioItem.agg){
      this.renderDonutChart();
    }
  }
},
 mounted(){

 },
 methods: {
  renderDonutChart(){
    const getChartOptions = () => {
      return {
        series: [
          store.xhrDataPortfolioItem?.investment?.trade_journal?.rates?.wins,
          store.xhrDataPortfolioItem?.investment?.trade_journal?.rates?.losses,
        ],
        colors: ["#1C64F2", "#f76868", "#FDBA8C", "#E74694"],
        chart: {
          height: 300,
          width: "100%",
          type: "donut",
        },
        stroke: {
          colors: ["transparent"],
          lineCap: "",
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontFamily: "Inter, sans-serif",
                  offsetY: 20,
                },
                total: {
                  showAlways: true,
                  show: true,
                  label: "W/L Ratio",
                  fontFamily: "Inter, sans-serif",
                  formatter: (w) => {
                    console.log(w)
                    // const sum = w.globals.seriesTotals.reduce((a, b) => {
                    //   return a + b
                    // }, 0)
                    return (store.xhrDataPortfolioItem.investment.trade_journal.rates.rate).toFixed(2) + "%"
                  },
                },
                value: {
                  show: true,
                  fontFamily: "Inter, sans-serif",
                  offsetY: -20,
                  formatter: function (value) {
                    return value
                  },
                },
              },
              size: "80%",
            },
          },
        },
        grid: {
          padding: {
            top: -2,
          },
        },
        labels: ["Wins", "Losses"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: window.screen.width <= 700 ? "bottom": "right",
          fontFamily: "Inter, sans-serif",
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value + " Trades"
            },
          },
        },
        xaxis: {
          labels: {
            formatter: function (value) {
              return value + " Trades"
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
      }
    }

    if (document.getElementById("tab-paper-info-metric-donut-chart") && typeof ApexCharts !== 'undefined') {
      const chart = new window.ApexCharts(document.getElementById("tab-paper-info-metric-donut-chart"), getChartOptions());
      chart.render();
    }
  },
 }
}
</script>

<style scoped>

</style>