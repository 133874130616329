<script setup>
import {defineProps } from 'vue';

import { Handle, Position } from '@vue-flow/core'

const props = defineProps(['id', 'findNode', 'label'])
// const toProperCase = (_string) => {
//     return _string.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
// };

// const labelTitlized = toProperCase(props.label.split("-").join(" "))

const onInput = (e) => {
  let targetNode = props.findNode(props.id)
  targetNode.data = {...targetNode.data, ...{attributeData: e.target.value}}
  // targetNode.dataTransfer.setData('application/vueflow-attribute-data', "nodeAttributeType")
  console.log("input", targetNode, e)
}
</script>

<template>
  <div>
    <div class="mb-4">
      <Handle type="target" :position="Position.Top" />
      <label for="success" class="block mb-2 text-sm font-medium">{{ props.label }}</label>
      <div class="flex justify-center">
          <input
            @input="onInput"
            type="number"
            class="border border-green-500 p-2 block w-full text-center rounded border-0 bg-transparent px-3 outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
            id="exampleFormControlInputNumber"
            :value="props.findNode(props.id)?.data?.attributeData !== (undefined || '') ? props.findNode(props.id)?.data?.attributeData : 'Enter Value'"
            placeholder="Enter Value" />
      </div>
      <!-- <p class="mt-2 text-sm text-green-600 dark:text-green-500"><span class="font-medium">Well done!</span> Some success message.</p> -->
    </div>
    <Handle type="source" :position="Position.Bottom" />
  </div>
</template>

<style>

.vue-flow__node-standard_input_node {
  position: absolute;
  padding: 10px;
  border-radius: 3px;
  width: 150px;
  font-size: 12px;
  text-align: center;
  color: var(--vf-node-text);
  cursor: grab;
  transform-origin: 0 0;
  box-sizing: border-box;
  --vf-node-color: #1a192b;
  --vf-handle: var(--vf-node-color);
  --vf-box-shadow: var(--vf-node-color);

  background: var(--vf-node-bg);
  border-color: var(--vf-node-color);
  outline: none;
  border: 1px solid var(--vf-node-color);
  box-shadow: 0 0 0 0.5px var(--vf-box-shadow);
  --vf-node-color: #1a192b;
  --vf-handle: var(--vf-node-color);
  --vf-box-shadow: var(--vf-node-color);
  background: var(--vf-node-bg);
}
</style>