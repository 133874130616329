<template>
  <div class="flex flex-col relative bg-white">
    <!-- This example requires Tailwind CSS v2.0+ -->

    <button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button" class="z-50 fixed inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
   <span class="sr-only">Open sidebar</span>
   <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
   </svg>
</button>

    <aside id="default-sidebar" class="fixed top-0 left-0 z-40 h-screen transition-transform md:transform-none -translate-x-full" aria-label="Sidebar">
      <!-- component -->
    <!-- This is an example component -->
    <div class="flex h-screen justify-start items-start">

      <aside class="w-72" aria-label="Sidebar">
        <div class="m-4 px-3 py-4 overflow-y-auto rounded bg-gray-50 dark:bg-gray-800">
          <div data-v-3187f009="" class="flex-shrink-0 rounded-md flex justify-center bg-gray-800 mb-2"><img data-v-3187f009="" class="block lg:hidden h-8 w-auto" src="/images/logos/barpot_logo_only_w_text_alt.svg" alt="BarPot"><img data-v-3187f009="" class="hidden lg:block h-8 w-auto" src="/images/logos/barpot_logo_only_w_text_alt.svg" alt="BarPot"></div>
          <hr class="py-2 divide-y divide-dashed">
          <ul class="space-y-2">
            <li>
              <form action="/stock" autocomplete="off">   
                <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                <div class="relative">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"></path>
                        </svg>
                    </div>
                    <input @keyup="onPressSymbolSearch($event)" autocomplete="off" aria-autocomplete="none" type="search" name="symbol" id="default-search" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Stock" required="">
                </div>
                <ul :class="store.xhrDataStockSearch?.data.length > 0 ? 'absolute bg-white w-[80%] h-fit max-h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200 mostly-customized-scrollbar': 'absolute bg-white h-fit px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200'" aria-labelledby="dropdownSearchButton">
                    <li v-for="(item, index) in getAllItem(store.xhrDataStockSearch?.data)" :key="item.symbol + index">
                      <div class="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                        <a :href="'/stock?symbol=' + item.symbol" :for="'checkbox-item-' + index" class="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">{{item.symbol}}</a>
                      </div>
                    </li>
                  </ul>
            </form>
            </li>
            <li>
              <a href="/dashboard"
                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <svg class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                </svg>
                <span class="ml-3">Dashboard</span>
              </a>
            </li>
            <li>
              <a href="/product/plays_stocks"
                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <svg class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z">
                  </path>
                  <path
                    d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z">
                  </path>
                </svg>
                <span class="flex-1 ml-3 whitespace-nowrap">Momentum Trader</span>
                <span class="hidden inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-600 bg-blue-200 rounded-full dark:bg-blue-900 dark:text-blue-200">3</span>
              </a>
            </li>
            <li>
              <a href="/product/portfolio_trader"
                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <svg class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="flex-1 ml-3 whitespace-nowrap">Strategy Trader</span>
                <span class="inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-300">Free</span>
              </a>
            </li>
            <!-- <li>
              <a href="/product/algo_creator"
                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <svg class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z">
                  </path>
                </svg>
                <span class="flex-1 ml-3 whitespace-nowrap">Algo Creator</span>
                <span class="inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-300">Pro</span>
              </a>
            </li> -->
            <li>
              <a href="/account"
                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                <svg class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="flex-1 ml-3 whitespace-nowrap">Account</span>
              </a>
            </li>
            <div id="dropdown-cta" class="p-4 mt-6 rounded-lg bg-blue-50 dark:bg-blue-900" role="alert">
            <div class="flex items-center mb-3">
                <span class="bg-yellow-100 text-yellow-800 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-900">Notification</span>
                <button type="button" class="hidden ml-auto -mx-1.5 -my-1.5 bg-blue-50 inline-flex justify-center items-center w-6 h-6 text-blue-900 rounded-lg focus:ring-2 focus:ring-blue-400 p-1 hover:bg-blue-200 h-6 w-6 dark:bg-blue-900 dark:text-blue-400 dark:hover:bg-blue-800" data-dismiss-target="#dropdown-cta" aria-label="Close">
                  <span class="sr-only">Close</span>
                  <svg class="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                  </svg>
                </button>
            </div>
            <p class="mb-3 text-sm text-blue-800 dark:text-blue-400">
                Check out our latest feature, Strategy Trader that uses our AI models to forecast, analyze, and simulate your portfolio that you create! 
            </p>
            <p class="mb-3 text-sm text-blue-800 dark:text-blue-400 italic">
                Free for a limited time!
            </p>
            <a class="text-sm text-blue-800 underline font-medium hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-300" href="http://localhost:8080/product/portfolio_trader">Try it out now!</a>
          </div>
          </ul>
        </div>
      </aside>
    </div>
    
    </aside>
    
    <!-- Render Your Content -->
    <slot></slot>
</div>
</template>

<script>

import { reactive } from 'vue'

const store = reactive({
    xhrDataStockSearch: {data: [], timestamp: null},

    // {"symbol": {"checked": true}}
    selectSymbol: {
        // "AAPL": {"checked": true, "symbol": "AAPL"}
    },
    isAutoSelectStocks: false,
})

export default {
 props: {
   risk_indicators: Object,
   data: Object
 },
 mounted(){
 },
 data(){
     return {
         store
     }
 },
 methods: {
    handleSignout(){
        localStorage.removeItem("barpot_jwt_access")
        localStorage.removeItem("barpot_jwt_refresh")
        window.location = "/login"
    },
    handleAccount(){
        window.location = "/account"
    },
    handleHome(){
        window.location = "/dashboard"
    },
    getAllItem(data){
        return data.filter(d => Object.keys(store.selectSymbol).indexOf(d.symbol) === -1)
    },
    async onPressSymbolSearch(event){
        console.log(store)
          var requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };
          fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/stock_search?keyword=${event.target.value}`, requestOptions)
            .then(response => response.json())
            .then(respData => {
              if (store.xhrDataStockSearch.timestamp !== null){
                if (respData.timestamp > store.xhrDataStockSearch.timestamp){
                  store.xhrDataStockSearch = respData
                }
              } else {
                store.xhrDataStockSearch = respData
              }
              return
            })
            .catch(error => console.log('error', error));
            return
        },
 }
 
}
</script>

<style scoped>
.Header {
  margin-bottom: 15px;
}
.hidden-as-sec-look {
  opacity: 0.3;
  -webkit-text-security: disc;
}
</style>