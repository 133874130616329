<template>
<div class="slider mb-4">
<div class="flex flex-row slide-track">
  <div class="flex flex-col slide-track mx-4" v-for="reportItem in xhrReportingData" v-bind:key="reportItem.id" >
      <div class="flex bg-gray-100 rounded-lg overflow-hidden text-base" v-on:click="navigateToSymbol(reportItem)">
        <button class="font-bold focus:outline-none py-1 px-6 bg-gradient-to-br from-indigo-600 to-indigo-700 hover:from-indigo-500 hover:to-indigo-700 text-white">${{reportItem.symbol}}</button>
        <button class="font-bold focus:outline-none py-1 px-6 text-black hover:bg-indigo-50">
          <p class="text-xl" style="color: #20BF55;">+{{reportItem.returnpct > 0 ? reportItem.returnpct.toFixed(2): 0}}%</p>
        </button>
      </div>
  </div>
</div>
</div>
</template>

<script>
import _ from 'lodash'

export default {
 props: {
   risk_indicators: Object,
   data: Object
 },
 mounted(){
   this.populateReporting()
 },
 data(){
     return {
         xhrReportingData: {},
     }
 },
 methods: {
  navigateToSymbol(item){
    window.location = `http://localhost:8080/stock?symbol=${item.symbol}`
  },
   async populateReporting(){
    // fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/reporting?rel=popular`,
     await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/portfolio_item_aggregate?portfolio_id=hiD7EB3AAW6Lx4aG3lsHudXnUOHL6QHlpaqhdyjAFzzlwEEc`, {
              method: 'GET', // or 'PUT'
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': localStorage.getItem("auth_tier_key")
              },
            })
            .then(response => response.json())
            .then(data => {
              try {
                this.xhrReportingData = _.uniqBy(data?.agg?.portfolio?.[data["id"]]?.data, (e) => {
                  return e.symbol;
                }).filter(v => v.returnpct > 2);
              } catch (error) {
                console.log(error)
              }
                
            });
   }
 }
 
}
</script>

<style scoped>
.Header {
  margin-bottom: 15px;
}
.hidden-as-sec-look {
  opacity: 0.3;
  -webkit-text-security: disc;
}


@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}


.slider {
	background: white;
	/* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125); */
	/* height: 100px; */
	margin: auto;
	overflow:hidden;
	position: relative;
	width: 100%;
}

.slider::before, .slider::after {
	background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
	content: "";
	height: 100px;
	position: absolute;
	width: 200px;
	z-index: 2;
}
	
.slider::after {
	right: 0;
	top: 0;
	transform: rotateZ(180deg);
}

.slider::before {
	left: 0;
	top: 0;
}
	
.slide-track {
	animation: scroll 300s linear infinite;
	display: flex;
}

.slide {
	height: 100px;
	width: 100%;
}

@media (min-width: 1900px) {

}

@media (min-width: 1440px) {

}

@media (min-width: 1281px) and (max-width: 1440px) {

}

@media (min-width: 1025px) and (max-width: 1280px) {

}

@media (min-width: 768px) and (max-width: 1024px) {

}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

}

@media (min-width: 481px) and (max-width: 767px) {

}

@media (min-width: 320px) and (max-width: 480px) {
.slider::before, .slider::after {
	background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
	content: "";
	height: 0;
	position: absolute;
	width: 0;
	z-index: 0;
}
}
</style>