<template>

  <baselineLayout>
  <link rel="stylesheet" href="/css/extra.css">
  <link rel="stylesheet" href="/css/SliderAnimation.css">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"/>
    <baselineBreadCrumbs/>
    <div class="p-4 2xl:ml-72 xl:ml-72 lg:ml-72 sm:ml-0 mr-4 h-full p-4 mt-2 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 bg-gray-100">
        <a :href="'/product/bot_instance?portfolio_id=' + store.urlObject.searchParams.get('portfolio_id')" v-if="store.urlObject.searchParams.get('portfolio_id') !== null" type="button" class="focus:outline-none text-white bg-blue-300 hover:bg-blue-500 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">View Portfolio</a>
      <div class="flex flex-row items-start h-full w-full mx-auto px-4 sm:px-6 sm:py-6 justify-center space-x-4">
        <section class="flex flex-col justify-center text-gray-600 space-y-2">
            <div class="flex items-center">
              <div class="flex w-48">
                <label for="email-address-icon" class="block mr-2 text-sm font-medium text-gray-900 dark:text-white">Portfolio Name</label>
              </div>
              <div class="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                  <span class="material-symbols-outlined">signature</span>
                </div>
                <input type="text" :value="store.configGenerate.name" @input="store.configGenerate.name = $event.target.value" id="email-address-icon" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Name of Portfolio">
              </div>
            </div>
            <div class="flex items-center">
              <div class="flex w-48">
                <label for="email-address-icon" class="block mr-2 text-sm font-medium text-gray-900 dark:text-white">Minimum Entry Price</label>
              </div>
              <div class="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                  <span class="material-symbols-outlined">remove</span>
                </div>
                <input type="number" :value="store.configGenerate.entry_price.min_entryprice" @input="store.configGenerate.entry_price.min_entryprice = $event.target.value" id="email-address-icon" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Minimum Entry Price">
              </div>
            </div>
            <div class="flex items-center">
              <div class="flex w-48">
                <label for="email-address-icon" class="block mr-2 text-sm font-medium text-gray-900 dark:text-white">Maximum Entry Price</label>
              </div>
              <div class="relative">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                  <span class="material-symbols-outlined">add</span>
                </div>
                <input type="number" :value="store.configGenerate.entry_price.max_entryprice" @input="store.configGenerate.entry_price.max_entryprice = $event.target.value" id="email-address-icon" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Maximum Entry Price">
              </div>
            </div>            
            
            <div class="flex items-center">
              <div class="flex w-48">
                <label for="email-address-icon" class="block mr-2 text-sm font-medium text-gray-900 dark:text-white">Risk Tolerance</label>
              </div>
              <genericCheckboxDropdown ref="riskToleranceGenericCheckboxDropdown" :selectedDefault="store.configGenerate.risk_tolerance.type" :customData="{isActive: true, data: store.configGenerate.risk_tolerance.custom}"/>
            </div>
            <div class="flex items-center">
              <div class="flex w-48">
                <label for="email-address-icon" class="block mr-2 text-sm font-medium text-gray-900 dark:text-white">Symbols</label>
              </div>
              <modifyStockDropdown ref="symbolsStockDropdown" :isAutomatic="store.configGenerate.securities.stocks.automatic" :defaultData="store.configGenerate.securities.stocks.symbols"/>
            </div>
            <div class="flex justify-between pt-4">
              <button @click="handleDeleteRequest()" v-if="store.urlObject.searchParams.get('operation_environment') == 'portfolio_modify'" type="button" class="focus:outline-none text-white bg-red-300 hover:bg-red-500 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Delete Portfolio</button>
              <button @click="handlePostRequest()" type="button" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">{{store.urlObject.searchParams.get('operation_environment') == 'portfolio_modify'? 'Update Portfolio': 'Create Portfolio'}}</button>
            </div>
        </section>
      </div>
    </div>
  </baselineLayout>
</template>

<script setup>
import { onMounted } from 'vue'
import { initFlowbite } from 'flowbite'

// initialize components based on data attribute selectors
onMounted(() => {
    initFlowbite();
})
</script>


<script>
import baselineLayout from './components/baselineLayout.vue'
import baselineBreadCrumbs from './components/baselineBreadCrumbs.vue'
import modifyStockDropdown from './components/portfolio_trader/modifyStockDropdown.vue'
import genericCheckboxDropdown from './components/portfolio_trader/genericCheckboxDropdown.vue'
import { reactive } from 'vue'
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

const $toast = useToast();

const store = reactive({
      xhrDataCardDataPopular: [
        ],
      xhrDataCardDataETF: [
        ],
      xhrDataCardData: {
        signal_stream: [],
        pagination: {
          curr_page: "",
          page_total: ""
        }
      },
      configGenerate: {
        version: 1,
        name: null,
        entry_price: {
          min_entryprice: null,
          max_entryprice: null,
        },
        risk_tolerance: {
          type: null, // custom | low | high | moderate
          custom: {
            neural_model_winrate_precent: {
              "label": "Minimum Winrate Chance",
              "value": 80,
            },
            neural_model_section_confidence: {
              "label": "Minimum Decision Confidence",
              "value": 90,
            }
          },
        },
        securities: {
          stocks: {
            automatic: null, // true|false
            symbols: {}
          }
        },
        simulation: {
            initial_investment: 10000,
        },
        user: {
            is_live: null,
            allow_notifications: null,
            is_public: null,
        }
      },
      urlObject: new URL(window.location.href),
      userData: {
        subscription_status: "",
        username: "...",
        email: "...",
        info: {
          phone_number: ""
        }
      },
})
export default {
  name: 'PageBotTraderCreate',
  components: {
    baselineLayout,
    baselineBreadCrumbs,
    modifyStockDropdown,
    genericCheckboxDropdown
  },
  data () {
      return {store}
  },
  mounted(){
    // set default date
    // this.$refs.startDatePicker.value = moment().subtract(86400 * 30, 'second').valueOf();

    this.refreshToken();
    this.getUser();
    setInterval(() => {
      console.log("Auto JWT refresh");
      this.refreshToken();
    }, 60000);

    console.log(store.urlObject.searchParams.get('portfolio_id'))
    if (store.urlObject.searchParams.get('portfolio_id') !== null){
      this.handleGetPortfolio()
    }

  },
  methods: {
        async refreshToken(){
          var formdata = new FormData();
          formdata.append("refresh", localStorage.getItem("barpot_jwt_refresh"));

          var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/login/refresh/`, requestOptions)
            .then(response => response.json())
            .then(result => {
              localStorage.setItem("barpot_jwt_access", result.access)
            })
            .catch(error => console.log('error', error));
        },
        async getUser(){
          var requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/user`, requestOptions)
            .then(response => {
              if(response.status == 401){
                alert("You are not Authorized to Access this Page! Please Sign up OR Sign in!")
                window.location = "/login"
                return response.json()
              }
              if(response.status == 403){
                return response.json()
              }
              return response.json()
            })
            .then(data => {
              store.userData = data
              localStorage.setItem("username", data["info"]["username"])
            })
            .catch(error => console.log('error', error));
        },
        handleSignout(){
          localStorage.removeItem("barpot_jwt_access")
          localStorage.removeItem("barpot_jwt_refresh")
          window.location = "/login"
        },
        handleAccount(){
          window.location = "/account"
        },
        handleHome(){
          window.location = "/dashboard"
        },
        promptForSignInKey() {
            var tier_key = prompt('Please enter your Tier Key (This is acquired only when you purchase a "paid tier" plan.)', "");
            if (tier_key != null) {
                localStorage.setItem("auth_tier_key", tier_key)
            }
        },
        async populateGridAsOverviewAsNonPremiumMember(){
            let url = new URL(window.location.href);
            let c_page = url.searchParams.get("page_num")
            await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/stream_overview?page_num=${c_page}`, {
              method: 'GET', // or 'PUT'
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
              },
            })
            .then(response => {
              if(response.status == 401){
                alert("You are not Authorized to Access this Page! Please Sign up OR Sign in!")
                window.location = "/login"
                return response.json()
              }
              if(response.status == 403){
                return response.json()
              }
              return response.json()
            })
            .then(data => {
                this.xhrDataCardData = {
                  ...this.xhrDataCardData,
                  ...data,
                }
            });
        },
        onStockSubmit(event){
          if (event.keyCode === 13){
            window.location.href = `/stock?symbol=${event.target.value}`
          }
          
        },
        handlePreviousPage(){
          let url = new URL(window.location.href);
          let c_page = parseInt(url.searchParams.get("page_num"))
          if (c_page == null || c_page <= 0){
            c_page = 0;
          }
          url.searchParams.set('page_num', (c_page - 1));
          window.location.href = url.href;
        },
        handleNextPage(){
          let url = new URL(window.location.href);
          let c_page = parseInt(url.searchParams.get("page_num"))
          if (c_page == null || c_page <= 0 || isNaN(c_page) == true){
            c_page = 0;
          }
          url.searchParams.set('page_num', this.xhrDataCardData.pagination.next_page);
          window.location.href = url.href;
        },
        handleBeginPage(){
          let url = new URL(window.location.href);
          url.searchParams.set('page_num', 0);
          window.location.href = url.href;
        },
        handleLastPage(){
          let url = new URL(window.location.href);
          url.searchParams.set('page_num', this.xhrDataCardData.pagination.page_total);
          window.location.href = url.href;
        },
        normalizeTime(){
            console.log(this.users)
        },
        objectMap (obj, fn) {
          return Object.fromEntries(
            Object.entries(obj).map(
              ([k, v], i) => [k, fn(v, k, i)]
            )
          )
        },
        async handleGetPortfolio(){
          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/portfolio_item?portfolio_id=${store.urlObject.searchParams.get('portfolio_id')}`, {
              method: 'GET', // or 'PUT'
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access"),
              },
            })
            .then(response => {
              return response.json()
            })
            .then(data => {
                if(data){
                  store.configGenerate.name = data.name
                  store.configGenerate.entry_price.min_entryprice = data.data.entry_price.min_entryprice
                  store.configGenerate.entry_price.max_entryprice = data.data.entry_price.max_entryprice
                  store.configGenerate.risk_tolerance.type = data.data.risk_tolerance.type
                  store.configGenerate.user.allow_notifications = data.user.allow_notifications
                  store.configGenerate.user.is_public = data.user.is_public
                  store.configGenerate.user.is_live = data.user.is_live
                  store.configGenerate.risk_tolerance.custom = {
                    ...store.configGenerate.risk_tolerance.custom, 
                    ...{
                    neural_model_winrate_precent: {
                      "label": "Minimum Winrate Chance",
                      "value": data.data.risk_tolerance?.custom?.neural_model_winrate_precent?.value ? data.data.risk_tolerance?.custom?.neural_model_winrate_precent?.value: 0,
                    },
                            neural_model_section_confidence: {
                      "label": "Minimum Decision Confidence",
                      "value": data.data.risk_tolerance?.custom?.neural_model_section_confidence?.value ? data.data.risk_tolerance?.custom?.neural_model_section_confidence?.value: 0,
                    },
                    }
                  }
                  store.configGenerate.securities.stocks.symbols = this.objectMap(data.data.securities.stocks.symbols, v => {
                    return {...v, ...{'checked': true}}
                  })
                  store.configGenerate.securities.stocks.automatic = data.data.securities.stocks.automatic
                  console.log("HEMMMM", store.configGenerate)
                }
                
            });
        },
        async handleDeleteRequest(){
          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/delete_portfolio_item_config`, {
              method: 'POST', // or 'PUT'
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
              },
              body: JSON.stringify({"id": store.urlObject.searchParams.get('portfolio_id')})
            })
            .then(response => {
              console.log(response.status)
              if(response.status == 401 || response.status == 500 || response.status == 404){
                $toast.open({
                  message: "Failed to Delete Portfolio Named: (" + store.configGenerate.name + ") | Code: " + response.status ,
                  type: 'error',
                  duration: 10000,
                  position: 'bottom'
                  // all of other options may go here
                });
              }
              return response.json()
            })
            .then(data => {
              console.log(data)
                $toast.open({
                  message: 'Deleted Portfolio' + " Named: (" + store.configGenerate.name + ") - was successful!",
                  type: 'success',
                  duration: 10000,
                  position: 'bottom'
                  // all of other options may go here
                });
               window.location = `/product/portfolio_trader`
            });
        },
        async handlePostRequest(){
          let isAutoSelectStocks = this.$refs.symbolsStockDropdown?.store?.isAutoSelectStocks
          let selectedSymbols = this.$refs.symbolsStockDropdown?.store?.selectSymbol
          let copyConfigGenerate =  JSON.parse(JSON.stringify(store.configGenerate));
          copyConfigGenerate.securities.stocks.automatic = isAutoSelectStocks
          copyConfigGenerate.securities.stocks.symbols = selectedSymbols

          copyConfigGenerate.risk_tolerance.type = this.$refs.riskToleranceGenericCheckboxDropdown?.store?.selectedItem?.value
          copyConfigGenerate.risk_tolerance.custom = this.$refs.riskToleranceGenericCheckboxDropdown?.store?.customData
          let postUrl = ``
          if (store.urlObject.searchParams.get('portfolio_id') !== null){
            postUrl = `${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/update_portfolio_item_config?portfolio_id=${store.urlObject.searchParams.get('portfolio_id')}`
          } else {
            postUrl = `${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/create_portfolio_item_config`
            $toast.open({
              message: "Creating New Portfolio Named: " + copyConfigGenerate.name,
              type: 'info',
              duration: 10000,
              position: 'bottom'
              // all of other options may go here
            });
          }

          await fetch(postUrl, {
              method: 'POST', // or 'PUT'
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
              },
              body: JSON.stringify(copyConfigGenerate)
            })
            .then(response => {
              if(response.status == 401 || response.status == 500 || response.status == 404){
                $toast.open({
                  message: "Failed to create Portfolio Named: " + copyConfigGenerate.name + " | Code: " + response.status ,
                  type: 'error',
                  duration: 10000,
                  position: 'bottom'
                  // all of other options may go here
                });
              }
              return response.json()
            })
            .then(data => {
              if (data?.status == "ok"){
                let _operation_type_text = store.urlObject.searchParams.get('operation_environment') == 'portfolio_modify'? 'Updated Portfolio': 'Created Portfolio'
                $toast.open({
                  message: _operation_type_text + " Named: " + copyConfigGenerate.name + " - was successful!",
                  type: 'success',
                  duration: 10000,
                  position: 'bottom'
                  // all of other options may go here
                });
               window.location = `/product/bot_instance?portfolio_id=${data.data._id}`
              }

              if (data?.status == "error"){
                $toast.open({
                  message: "Error: " + data.message,
                  type: 'error',
                  duration: 10000,
                  position: 'bottom'
                  // all of other options may go here
                });
              }
            });
        },
        generateRandomNumber(min, max) {
            let highlightedNumber = Math.random() * (max - min) + min;
            return(highlightedNumber);
        }
    }
}

</script>

<style>
.toggle-checkbox:checked {
  right: 0;
  border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
  background-color: #68D391;
}
.k-line-chart-container {
  display: flex;
  flex-direction: column;
  margin: 1em 0em;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px;
  background-color: #ffffff;
  width: 95%;
  height: 35vw;
  padding: 16px 6px 16px 16px;
}
</style>