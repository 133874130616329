<script setup>
import { Panel, VueFlow, isNode, useVueFlow } from '@vue-flow/core'
import { Background } from '@vue-flow/background'
import { Controls } from '@vue-flow/controls'
import { MiniMap } from '@vue-flow/minimap'
import { ref, defineProps, watch } from 'vue'
// import { MarkerType } from '@vue-flow/core'
import StandardNode from '../vue-flow-nodes/portfolioTrader/StandardNode.vue'
import StandardInputNode from '../vue-flow-nodes/StandardInputNode.vue'
// make sure to include the necessary styles!
import '@vue-flow/node-resizer/dist/style.css'
const props = defineProps(['portfolioItemHttpRequestData'])
/**
 * You can pass elements together as a v-model value
 * or split them up into nodes and edges and pass them to the `nodes` and `edges` props of Vue Flow (or useVueFlow composable)
 */
let initialElements = [
//   { id: '1', type: 'input', label: 'Node 1', position: { x: 250, y: 0 }, class: 'light' },
//   { id: '2', type: 'output', label: 'Node 2', position: { x: 100, y: 100 }, class: 'light' },
//   { id: '3', label: 'Node 3', position: { x: 400, y: 100 }, class: 'light' },
//   { id: '4', label: 'Node 4', position: { x: 150, y: 200 }, class: 'light' },
//   { id: '5', type: 'output', label: 'Node 5', position: { x: 300, y: 300 }, class: 'light' },
//   { id: 'e1-2', source: '1', target: '2', animated: true },
//   { id: 'e1-3', label: 'edge with arrowhead', source: '1', target: '3', markerEnd: MarkerType.ArrowClosed },
//   {
//     id: 'e4-5',
//     type: 'step',
//     label: 'step-edge',
//     source: '4',
//     target: '5',
//     style: { stroke: 'orange' },
//     labelBgStyle: { fill: 'orange' },
//   },
//   { id: 'e3-4', type: 'smoothstep', label: 'smoothstep-edge', source: '3', target: '4' },
]

let parameterVisConfig = {
    name: {
      display: false,
      name: "Name",
      formatter: (string) => string
    },
    max_entryprice: {
      display: true,
      name: "(Max) Entry Price",
      formatter: (string) => "$" + string
    },
    min_entryprice: {
      display: true,
      name: "(Min) Entry Price",
      formatter: (string) => "$" + string
    },
    neural_model_winrate_precent: {
      display: true,
      name: "Neural Winrate",
      formatter: (string) => string + "%"
    },
    start_epoch: {
      display: false,
      name: "start_epoch",
      formatter: (string) => string
    },
    winrate_precent: {
      display: true,
      name: "Alert Confidence",
      formatter: (string) => string + "%"
    },
    win_rate_pct: {
      display: true,
      name: "Overall Winrate",
      formatter: (string) => string + "%"
    },
    neural_model_section_confidence: {
      display: true,
      name: "Overall Winrate",
      formatter: (string) => string + "%"
    },
}

let dat = {}
// let dat = {
//   "max_entryprice": 30,
//   "min_entryprice": 5,
//   "neural_model_winrate_precent": 70,
//   "neural_model_section_confidence": 90,
//   "start_epoch": 1682899200,
//   "id": "ghbytsrbzuccnwmzzljrycbygkshtsaddrqpxitgbtvgqbxa",
//   "name": "First Model Make"
// }

let stock_sym = {}
// let stock_sym = {
//     "ABOS": {},
//     "APPL": {},
//     "GOOG": {},
//     "DSDS": {},
//     "CXCX": {},
//     "GGGD": {},
//     "HHFF": {},
//     "JKJK": {},
//     "FDFD": {},
//     "VCVC": {},
// }
const populateData = (newValue) => {
            dat = newValue.agg.portfolio[newValue.id].portfolio_meta
            stock_sym = newValue.agg.portfolio[newValue.id].stock_symbols
            console.log("🚀 ~ file: breakdownFlowPortfolioTrader.vue:104 ~ dat:", dat)

            let myData = {"name": {"pos": 0, "label": "Name", value: dat?.name, "type": "default_node"},
                            "start_date": {"pos": 1, "label": "Starting Date From", value: dat?.time_period?.start_date, "type": "time"},
                            "end_date": {"pos": 2, "label": "Ending Date at", value: null, "type": "time"},
                            "max_entryprice": {"pos": 3, "label": "Entry Price Less Than", value: dat?.entry_price?.max_entryprice, "type": "default_node"},
                            "min_entryprice": {"pos": 4, "label": "Entry Price More Than", value: dat?.entry_price?.min_entryprice, "type": "default_node"},
                            "neural_model_section_confidence": {"pos": 5, "label": "Decision Confidence More Than", value: dat?.risk_tolerance?.custom?.neural_model_section_confidence?.value,"type": "default_node"},
                            "neural_model_winrate_precent": {"pos": 6, "label": "Winrate Chance More Than", value: dat?.risk_tolerance?.custom?.neural_model_winrate_precent?.value, "type": "default_node"}
                        }

            let arrayOfSortedObjects = Object.keys(myData).sort((a,b) => {
                return myData[a].pos - myData[b].pos;
            }).map(function(k) {
                myData[k]["id"] = k
                return myData[k];
            });
            console.log(11212, arrayOfSortedObjects)

            let newElements = []
            for (let idx = 0; idx < arrayOfSortedObjects.length; idx++) {
                let item = arrayOfSortedObjects[idx]
                let assignValue = item.value
                if (parameterVisConfig[item.id]?.display == true){
                    assignValue = parameterVisConfig[item.id]?.formatter(item.value)
                }
                let assignType = item.type
                if (item["type"] == "time"){
                    assignValue = new Date(item.value == null ? new Date().getTime():(item.value)).toLocaleString()
                    assignType = "default_node"
                }
                newElements.push(
                    { id: item.pos,
                    type: assignType,
                    label: `<div class="flex flex-col">
                    <span data-v-2e83651c="" class="font-semibold text-gray-900 underline dark:text-white decoration-gray-500">${item.label}</span> 
                    <span data-v-2e83651c="" class="text-transparent bg-clip-text bg-gradient-to-r to-lime-600 from-sky-400">${assignValue}</span></div>`,
                    position: { x: idx % 2 === 0 ? 250 + (75): 250 - (75) , y: (95 * idx) }, class: 'light' }
                )
                if (idx > 0){
                    let previousItem = arrayOfSortedObjects[idx - 1]
                    // Example: { id: `e1-2`, source: '0', target: '1', animated: true }
                    newElements.push({ id: `e${previousItem.pos}-${item.pos}`, source: (previousItem.pos).toString(), target: (item.pos).toString(), animated: true })
                    
                    // Put stock parents
                    if (idx == (arrayOfSortedObjects.length - 1)){
                        // Child of
                        let nextChildOfRel_X = 30
                        let nextChildOfRel_Y = 50
                        let totalChildRows = 1
                        let gen_childen = Object.keys(stock_sym).map((item, idx) => {
                                if ((idx + 1) % 4 === 0){
                                    nextChildOfRel_Y += 70
                                    nextChildOfRel_X = 30
                                    // Count how many rows
                                    totalChildRows += 1
                                } else {
                                    nextChildOfRel_X += 70
                                }
                            return {
                                id: `gen_p_node_parent_stock_0-${item}`,
                                label: item,
                                type: "default_node",
                                position: { x: new Number(nextChildOfRel_X), y: new Number(nextChildOfRel_Y) },
                                style: { backgroundColor: 'rgba(16, 185, 129, 0.9)', width: '50px', height: '40px' },
                                parentNode: 'gen_p_node_parent_stock_0',
                                }
                            })
                        newElements.push(...gen_childen)
                        console.log("111111111111---", totalChildRows)
                        // NOTE: the last item is considered to be: item.pos
                        // y = 95 === the distance defined at the base x for on line 138 
                        newElements.push(
                            {
                                id: 'gen_p_node_parent_stock_0',
                                label: `Stocks (Holdings : ${gen_childen.length})`,
                                position: { x: 250, y: (95 * (idx + 1)) },
                                style: { backgroundColor: 'rgba(44, 137, 197, 0.3)', width: '320px', height: totalChildRows > 0 ? `${75 * totalChildRows}px` :'200px' },
                            },
                            { id: `e_gen_p_node_parent_stock_0-${previousItem.pos}`, source: (item.pos).toString(), target: "gen_p_node_parent_stock_0", animated: true }
                        )
                    }
                }
            }
            // initialElements = [...[{ id: `e1-2`, source: '0', target: '1', animated: true }], ...newElements]

            // initialElements = [...[{
            //       id: '2',
            //       label: 'parent node',
            //       position: { x: 100, y: 100 },
            //       style: { backgroundColor: 'rgba(16, 185, 129, 0.5)', width: '200px', height: '200px' },
            //     },
            //     {
            //       id: '2a',
            //       label: 'child node',
            //       position: { x: 10, y: 50 },
            //       parentNode: '2',
            //     }],
            // ...newElements]

            initialElements = newElements
            console.log("🚀 ~ file: breakdownFlowPortfolioTrader.vue:218 ~ watch ~ initialElements:", initialElements)
            elements = ref(newElements)
}

/**
 * Our elements
 */
let elements = ref(initialElements)

watch(() => props.portfolioItemHttpRequestData, (newValue) => { 
    if(newValue.id){
        populateData(newValue)
    }
});

if(props.portfolioItemHttpRequestData?.id){
    populateData(props.portfolioItemHttpRequestData)
}
/**
 * useVueFlow provides all event handlers and store properties
 * You can pass the composable an object that has the same properties as the VueFlow component props
 */
const { onPaneReady, onNodeDragStop, onConnect, addEdges, findNode, setTransform, toObject } = useVueFlow()



/**
 * This is a Vue Flow event-hook which can be listened to from anywhere you call the composable, instead of only on the main component
 *
 * onPaneReady is called when viewpane & nodes have visible dimensions
 */
onPaneReady(({ fitView }) => {
  fitView()
})

onNodeDragStop((e) => console.log('drag stop', e))

/**
 * onConnect is called when a new connection is created.
 * You can add additional properties to your new edge (like a type or label) or block the creation altogether
 */
onConnect((params) => addEdges(params))

const dark = ref(false)

/**
 * To update node properties you can simply use your elements v-model and mutate the elements directly
 * Changes should always be reflected on the graph reactively, without the need to overwrite the elements
 */
function updatePos() {
  return elements.value.forEach((el) => {
    if (isNode(el)) {
      el.position = {
        x: Math.random() * 400,
        y: Math.random() * 400,
      }
    }
  })
}

/**
 * toObject transforms your current graph data to an easily persist-able object
 */
function logToObject() {
  return console.log(toObject())
}

/**
 * Resets the current viewpane transformation (zoom & pan)
 */
function resetTransform() {
  return setTransform({ x: 0, y: 0, zoom: 1 })
}

function toggleClass() {
  return (dark.value = !dark.value)
}
</script>

<template>
  <VueFlow v-model="elements" :class="{ dark }" class="basicflow" :default-viewport="{ zoom: window?.screen?.width >= 700 ? 4.5: 0.5 }" :min-zoom="0.2" :max-zoom="4">
    <Background :pattern-color="dark ? '#FFFFFB' : '#aaa'" gap="8" />

    <MiniMap v-if="window?.screen?.width >= 700"/>

    <Controls />

    <Panel position="top-right" class="controls">
      <button style="background-color: #113285; color: white" title="Reset Transform" @click="resetTransform">
        <svg width="16" height="16" viewBox="0 0 32 32">
          <path fill="#FFFFFB" d="M18 28A12 12 0 1 0 6 16v6.2l-3.6-3.6L1 20l6 6l6-6l-1.4-1.4L8 22.2V16a10 10 0 1 1 10 10Z" />
        </svg>
      </button>

      <button class="hidden" style="background-color: #6f3381" title="Shuffle Node Positions" @click="updatePos">
        <svg width="16" height="16" viewBox="0 0 24 24">
          <path
            fill="#FFFFFB"
            d="M14 20v-2h2.6l-3.2-3.2l1.425-1.425L18 16.55V14h2v6Zm-8.6 0L4 18.6L16.6 6H14V4h6v6h-2V7.4Zm3.775-9.425L4 5.4L5.4 4l5.175 5.175Z"
          />
        </svg>
      </button>

      <button
        class="hidden"
        :style="{ backgroundColor: dark ? '#FFFFFB' : '#292524', color: dark ? '#292524' : '#FFFFFB' }"
        @click="toggleClass"
      >
        <template v-if="dark">
          <svg width="16" height="16" viewBox="0 0 24 24">
            <path
              fill="#292524"
              d="M12 17q-2.075 0-3.537-1.463Q7 14.075 7 12t1.463-3.538Q9.925 7 12 7t3.538 1.462Q17 9.925 17 12q0 2.075-1.462 3.537Q14.075 17 12 17ZM2 13q-.425 0-.712-.288Q1 12.425 1 12t.288-.713Q1.575 11 2 11h2q.425 0 .713.287Q5 11.575 5 12t-.287.712Q4.425 13 4 13Zm18 0q-.425 0-.712-.288Q19 12.425 19 12t.288-.713Q19.575 11 20 11h2q.425 0 .712.287q.288.288.288.713t-.288.712Q22.425 13 22 13Zm-8-8q-.425 0-.712-.288Q11 4.425 11 4V2q0-.425.288-.713Q11.575 1 12 1t.713.287Q13 1.575 13 2v2q0 .425-.287.712Q12.425 5 12 5Zm0 18q-.425 0-.712-.288Q11 22.425 11 22v-2q0-.425.288-.712Q11.575 19 12 19t.713.288Q13 19.575 13 20v2q0 .425-.287.712Q12.425 23 12 23ZM5.65 7.05L4.575 6q-.3-.275-.288-.7q.013-.425.288-.725q.3-.3.725-.3t.7.3L7.05 5.65q.275.3.275.7q0 .4-.275.7q-.275.3-.687.287q-.413-.012-.713-.287ZM18 19.425l-1.05-1.075q-.275-.3-.275-.712q0-.413.275-.688q.275-.3.688-.287q.412.012.712.287L19.425 18q.3.275.288.7q-.013.425-.288.725q-.3.3-.725.3t-.7-.3ZM16.95 7.05q-.3-.275-.287-.688q.012-.412.287-.712L18 4.575q.275-.3.7-.288q.425.013.725.288q.3.3.3.725t-.3.7L18.35 7.05q-.3.275-.7.275q-.4 0-.7-.275ZM4.575 19.425q-.3-.3-.3-.725t.3-.7l1.075-1.05q.3-.275.713-.275q.412 0 .687.275q.3.275.288.688q-.013.412-.288.712L6 19.425q-.275.3-.7.287q-.425-.012-.725-.287Z"
            />
          </svg>
        </template>

        <template v-else>
          <svg width="16" height="16" viewBox="0 0 24 24">
            <path
              fill="#FFFFFB"
              d="M12 21q-3.75 0-6.375-2.625T3 12q0-3.75 2.625-6.375T12 3q.35 0 .688.025q.337.025.662.075q-1.025.725-1.637 1.887Q11.1 6.15 11.1 7.5q0 2.25 1.575 3.825Q14.25 12.9 16.5 12.9q1.375 0 2.525-.613q1.15-.612 1.875-1.637q.05.325.075.662Q21 11.65 21 12q0 3.75-2.625 6.375T12 21Z"
            />
          </svg>
        </template>
      </button>

      <button class="hidden" title="Log `toObject`" @click="logToObject">
        <svg width="16" height="16" viewBox="0 0 24 24">
          <path
            fill="#292524"
            d="M20 19V7H4v12h16m0-16a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16m-7 14v-2h5v2h-5m-3.42-4L5.57 9H8.4l3.3 3.3c.39.39.39 1.03 0 1.42L8.42 17H5.59l3.99-4Z"
          />
        </svg>
      </button>
      
    </Panel>
    <template #node-standard_input_node="standardInputNodeProps">
        <StandardInputNode
        :id="standardInputNodeProps.id"
        :data="standardInputNodeProps.data"
        :label="standardInputNodeProps.label"
        :findNode="findNode" />
    </template>
    <template #node-standard_node="standardNodeProps">
        <StandardNode
        :data="standardNodeProps.data"
        :label="standardNodeProps.label" />
    </template>
  </VueFlow>
</template>

<style>
.basicflow {
  height: 32vw;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #494949;
}
.container {
    min-height: calc(100vh - 100px);
}
.column {
    border-right: 1px solid #494949;
}
.column ul {
    padding-inline-start: 0px;
    padding: 10px 10px;
 
}
.column li {
    background: transparent;
}

.node {
    border-radius: 8px;
    border: 2px solid #494949;
    display: block;
    height:60px;
    line-height:40px;
    padding: 10px;
    margin: 10px 0px;
    cursor: move;

}
#drawflow {
  width: 100%;
  height: 100%;
  text-align: initial;
  background: #2b2c30;
  background-size: 20px 20px;
  background-image: radial-gradient(#494949 1px, transparent 1px);
  
}

@media (min-width: 1900px) {

}

@media (min-width: 1440px) {

}

@media (min-width: 1281px) and (max-width: 1440px) {

}

@media (min-width: 1025px) and (max-width: 1280px) {

}

@media (min-width: 768px) and (max-width: 1024px) {
  .basicflow {
    height: 102vw;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .basicflow {
    height: 102vw;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .basicflow {
    height: 102vw;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .basicflow {
    height: 102vw;
  }
}
</style>
