<template>

  <baselineLayout>
  <link rel="stylesheet" href="/css/extra.css">
  <link rel="stylesheet" href="/css/SliderAnimation.css">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"/>
    <baselineBreadCrumbs/>
    <div class="p-4 2xl:ml-72 xl:ml-72 lg:ml-72 sm:ml-0 mr-4 h-full p-4 mt-2 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 bg-gray-100">

      <div class="flex flex-col items-center w-full mx-auto px-4 sm:px-6 sm:py-6 justify-center space-x-4">
        <!-- Table -->
        <div class="w-full max-w-fit mx-auto bg-white shadow-lg rounded-sm border border-gray-200">
        <!-- Snippet -->
        <section v-if="store.userData?.info?.username == 'simple'" class="flex flex-col justify-center antialiased bg-gray-100 text-gray-600">
            <div class="flex">
                    <div class="relative w-full">
                        <input type="number" id="account-amount" class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-s-lg border-e-gray-50 border-e-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-e-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" value="50000" placeholder="$50000" required>
                    </div>
                    <button id="investment-apply-button" @click="handleCreatePaperAccount()" class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-e-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600" type="button">
                        <span class="material-symbols-outlined text-sm">attach_money</span>
                        Create New Acount
                    </button>
                </div>
            <div class="w-full mx-auto p-4 sm:px-6 h-full">
                <analyticsChart />
            </div>
        </section>
            <header class="flex justify-center items-center justify-between flex-row px-5 py-4 border-b border-gray-100">
                <h2 class="font-semibold text-gray-800 text-xl">Your Strategies 🚀</h2>
                  <a href="/product/portfolio_operation" class="flex items-center text-white bg-green-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"><span class="material-symbols-outlined mr-2">bento</span> Create New Portfolio</a>
            </header>
            <div class="p-3">
                <div class="overflow-x-auto">
                    <table class="table-auto w-full">
                        <thead class="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                            <tr>
                                <th class="p-2 whitespace-nowrap">
                                    <div class="font-semibold text-left">Name</div>
                                </th>
                                <th class="p-2 whitespace-nowrap">
                                    <div class="font-semibold text-left">Creator</div>
                                </th>
                                <th class="p-2 whitespace-nowrap">
                                    <div class="font-semibold text-left">Cumulative Return %</div>
                                </th>
                                <th class="p-2 whitespace-nowrap">
                                    <div class="font-semibold text-left">Cumulative Value</div>
                                </th>
                                <th class="p-2 whitespace-nowrap">
                                    <div class="font-semibold text-left">Win/Ratio</div>
                                </th>
                                <th class="p-2 whitespace-nowrap">
                                    <div class="font-semibold text-center">Explore</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="Object.keys(store.http_portfolio_agg.portfolio).length > 0" class="text-sm divide-y divide-gray-100">
                            <tr @click="handlePortfolioRowClick(portfolio_sing)" v-for="portfolio_sing in Object.keys(store.http_portfolio_agg.portfolio)" v-bind:key="portfolio_sing">
                                <td class="p-2">
                                    <div class="flex items-center">
                                        <div class="flex mr-1 2xl:flex-row xl:flex-row md:flex-row sm:flex-col">
                                          <SparklineComponent v-bind:data="this.shrinkArray(store.http_portfolio_agg.portfolio[portfolio_sing]['cumulative_return_timeline'], 30)" />
                                          <p class="text-xs	italic 2xl:hidden xl:hidden md:hidden sm:visible">Click to view</p>
                                          <!-- <SparklineComponent v-bind:data="[2, 3, 5, 7, -11, 13, 17, 19, 23, 29, 31]" /> -->
                                          </div>
                                        <div class="font-medium text-gray-800 w-64">{{store.http_portfolio_agg.portfolio[portfolio_sing]?.portfolio_meta?.name}}
                                        </div>
                                    </div>
                                </td>
                                <td class="p-2 whitespace-nowrap">
                                    <span class="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300">{{store.http_portfolio_agg.portfolio[portfolio_sing]?.user.is_owner ? 'You ⭐': 'Community'}}</span>
                                </td>
                                <td class="p-2 whitespace-nowrap">
                                    <div :class="store.http_portfolio_agg.portfolio[portfolio_sing]?.cumulative_return_pct > 0 ? 'text-center font-medium text-gray text-green-500': 'text-center font-medium text-gray text-red-500'">{{formatter.format(store.http_portfolio_agg.portfolio[portfolio_sing]?.cumulative_return_pct)}}%</div>
                                </td>
                                <td class="p-2 whitespace-nowrap">
                                    <div class="text-center font-medium">${{parseFloat(store.http_portfolio_agg.portfolio[portfolio_sing]?.cumulative_value).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}}</div>
                                </td>
                                <td class="p-2 whitespace-nowrap">
                                    <div :class="store.http_portfolio_agg.portfolio[portfolio_sing]?.trades_analysis?.winrate > 60 ? 'text-center font-medium text-gray text-green-500': 'text-center font-medium text-gray text-red-500'">{{store.http_portfolio_agg.portfolio[portfolio_sing]?.trades_analysis?.winrate !== null ? (parseFloat(store.http_portfolio_agg.portfolio[portfolio_sing]?.trades_analysis?.winrate).toFixed(2).toString() + ("%")).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "0%"}}</div>
                                </td>
                                <td class="p-2 whitespace-nowrap">
                                    <div class="inline-flex rounded-md shadow-sm" role="group">
                                    <a v-bind:href=' "/product/bot_instance?portfolio_id=" + store.http_portfolio_agg.portfolio[portfolio_sing]?.portfolio_meta?.id' type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                                      <span class="material-symbols-outlined mr-1">explore</span>
                                      Explore
                                    </a>
                                    <a v-bind:href=' "/product/portfolio_operation?operation_environment=portfolio_modify&portfolio_id=" + store.http_portfolio_agg.portfolio[portfolio_sing]?.portfolio_meta?.id' type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                                      <span class="material-symbols-outlined mr-1">edit</span>
                                      Edit
                                    </a>
                                  </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <div v-if="Object.keys(store.http_portfolio_agg.portfolio).length <= 0" class="flex flex-col w-full overflow-x-auto items-center py-4">
                          <semipolar-spinner
                            :animation-duration="2000"
                            :size="120"
                            color="#0051d8"
                          />
                          <progressBar class="flex mt-8" />
                      </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </baselineLayout>
</template>

<script>
import baselineLayout from './components/baselineLayout.vue'
import { SemipolarSpinner  } from 'epic-spinners'
import SparklineComponent from './components/SparklineComponent.vue'
import baselineBreadCrumbs from './components/baselineBreadCrumbs.vue'
import progressBar from './components/progressBar.vue'
import analyticsChart from './components/analyticsChart.vue'
import { reactive } from 'vue'

import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
const $toast = useToast();

const formatter = new Intl.NumberFormat("en-GB", { style: "decimal",  signDisplay: 'always' });

const store = reactive({
      xhrDataCardDataPopular: [
        ],
      xhrDataCardDataETF: [
        ],
      xhrDataCardData: {
        signal_stream: [],
        pagination: {
          curr_page: "",
          page_total: ""
        }
      }
        ,
      risk_indicators: {
          low: true,
          high: true,
          moderate: true
      },
      userData: {
        subscription_status: "",
        username: "...",
        email: "...",
        info: {
          phone_number: ""
        }
      },
      http_portfolio_agg: {
    portfolio: {}
  },
  expanded: {
    
  },
  parameterVisConfig: {
    name: {
      display: false,
      name: "Name",
      formatter: (string) => string
    },
    max_entryprice: {
      display: true,
      name: "(Max) Entry Price",
      formatter: (string) => "$" + string
    },
    min_entryprice: {
      display: true,
      name: "(Min) Entry Price",
      formatter: (string) => "$" + string
    },
    neural_model_winrate_precent: {
      display: true,
      name: "Neural Winrate",
      formatter: (string) => string + "%"
    },
    start_epoch: {
      display: false,
      name: "start_epoch",
      formatter: (string) => string
    },
    winrate_precent: {
      display: true,
      name: "Alert Confidence",
      formatter: (string) => string + "%"
    },
    win_rate_pct: {
      display: true,
      name: "Overall Winrate",
      formatter: (string) => string + "%"
    },
  }
})
export default {
  name: 'PageBotTraderDashboard',
  components: {
    baselineLayout,
    baselineBreadCrumbs,
    SparklineComponent,
    SemipolarSpinner,
    progressBar,
    analyticsChart
  },
  data () {
      return {store, formatter}
  },
  mounted(){
      this.refreshToken();
      this.getUser();
      this.getModelFolios();
      setInterval(() => {
        console.log("Auto JWT refresh");
        this.refreshToken();
      }, 60000);
  },
  methods: {
        async refreshToken(){
          var formdata = new FormData();
          formdata.append("refresh", localStorage.getItem("barpot_jwt_refresh"));

          var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/login/refresh/`, requestOptions)
            .then(response => response.json())
            .then(result => {
              localStorage.setItem("barpot_jwt_access", result.access)
            })
            .catch(error => console.log('error', error));
        },
        async handleCreatePaperAccount(){
          const payloadData = {
            amount: document.getElementById("account-amount").value,
          }
          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/portfolio_trader/invest/paper/account/create`, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            },
            body: JSON.stringify(payloadData)
          })
          .then(response => {
            if(response.status == 401 || response.status == 500 || response.status == 404){
              $toast.open({
                message: "Failed to update Portfolio | Code: " + response.status ,
                type: 'error',
                duration: 10000,
                position: 'bottom'
                // all of other options may go here
              });
            }
            return response.json()
          })
          .then(data => {
            if (data?.code == "token_not_valid" || data?.status == "portfolio_item.user_not_owner"){
              $toast.open({
                message: "Could not complete operation you are not authorized.",
                type: 'error',
                duration: 10000,
                position: 'bottom'
                // all of other options may go here
              });
              return
            }
              $toast.open({
                message: data?.message,
                type: data?.status == "warn" ? 'warning': 'success',
                duration: 10000,
                position: 'bottom'
                // all of other options may go here
              });
          });
        },
        async getUser(){
          var requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/user`, requestOptions)
            .then(response => {
              if(response.status == 401){
                alert("You are not Authorized to Access this Page! Please Sign up OR Sign in!")
                window.location = "/login"
                return response.json()
              }
              return response.json()
              }
            )
            .then(data => {
              store.userData = data
              localStorage.setItem("username", data["info"]["username"])
            })
            .catch(error => console.log('error', error));
        },
        handleSignout(){
          localStorage.removeItem("barpot_jwt_access")
          localStorage.removeItem("barpot_jwt_refresh")
          window.location = "/login"
        },
        handleAccount(){
          window.location = "/account"
        },
        handleHome(){
          window.location = "/dashboard"
        },
        promptForSignInKey() {
            var tier_key = prompt('Please enter your Tier Key (This is acquired only when you purchase a "paid tier" plan.)', "");
            if (tier_key != null) {
                localStorage.setItem("auth_tier_key", tier_key)
            }
        },
        async populateGridAsOverviewAsNonPremiumMember(){
            let url = new URL(window.location.href);
            let c_page = url.searchParams.get("page_num")
            await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/stream_overview?page_num=${c_page}`, {
              method: 'GET', // or 'PUT'
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
              },
            })
            .then(response => {
              if(response.status == 401){
                alert("You are not Authorized to Access this Page! Please Sign up OR Sign in!")
                window.location = "/login"
                return response.json()
              }
              if(response.status == 403){
                return response.json()
              }
              return response.json()
            })
            .then(data => {
                this.xhrDataCardData = {
                  ...this.xhrDataCardData,
                  ...data,
                }
            });
        },
        async toggleRiskClick(){
          this.populateGrid()
        },
        async populateGrid(){
            let url = new URL(window.location.href);
            let c_page = url.searchParams.get("page_num");
            let risk_types = [];
            if(document.getElementById("toggle_low").checked){
              risk_types.push("risk_low")
            }
            if(document.getElementById("toggle_high").checked){
              risk_types.push("risk_high")
            }
            if(document.getElementById("toggle_moderate").checked){
              risk_types.push("risk_moderate")
            }
            risk_types = risk_types.join(",")
            if (risk_types.length <= 0){
              risk_types = ["risk_low"]
            }
            await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/stream?page_num=${c_page}&risk_types=${risk_types}`, {
              method: 'GET', // or 'PUT'
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
              },
            })
            .then(response => {
              if(response.status == 401){
                alert("You are not Authorized to Access this Page! Please Sign up OR Sign in!")
                window.location = "/login"
                return response.json()
              }
              if(response.status == 403){
                this.populateGridAsOverviewAsNonPremiumMember()
                return response.json()
              }
              return response.json()
            })
            .then(data => {
                this.xhrDataCardData = {
                  ...this.xhrDataCardData,
                  ...data,
                }
            });
        },
        onStockSubmit(event){
          if (event.keyCode === 13){
            window.location.href = `/stock?symbol=${event.target.value}`
          }
          
        },
        handlePreviousPage(){
          let url = new URL(window.location.href);
          let c_page = parseInt(url.searchParams.get("page_num"))
          if (c_page == null || c_page <= 0){
            c_page = 0;
          }
          url.searchParams.set('page_num', (c_page - 1));
          window.location.href = url.href;
        },
        handleNextPage(){
          let url = new URL(window.location.href);
          let c_page = parseInt(url.searchParams.get("page_num"))
          if (c_page == null || c_page <= 0 || isNaN(c_page) == true){
            c_page = 0;
          }
          url.searchParams.set('page_num', this.xhrDataCardData.pagination.next_page);
          window.location.href = url.href;
        },
        handleBeginPage(){
          let url = new URL(window.location.href);
          url.searchParams.set('page_num', 0);
          window.location.href = url.href;
        },
        handleLastPage(){
          let url = new URL(window.location.href);
          url.searchParams.set('page_num', this.xhrDataCardData.pagination.page_total);
          window.location.href = url.href;
        },
        normalizeTime(){
            console.log(this.users)
        },
        generateRandomNumber(min, max) {
            let highlightedNumber = Math.random() * (max - min) + min;
            return(highlightedNumber);
        },
        shrinkArray(array, size) {
          const step = array.length / size
          return array.filter((v, i) => Math.floor(i % step) == 0)
        },
        paramVis(data){
    let _m = {}
    Object.keys(data).map(item => {
      if (store.parameterVisConfig[item]?.display == true){
        _m[item] = data[item]
      }
    })
    return _m
  },
        async getModelFolios(){
            var requestOptions = {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
              }
            };
              await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/portfolio_trader/get_all_portfolios/`, requestOptions)
              .then(response => response.json())
              .then(data => {
                console.warn("HHHHH", data)
                store.http_portfolio_agg = data
              })
              .catch(error => console.log('error', error));
  },
  handlePortfolioRowClick(portfolio_id){
    window.location.href = '/product/bot_instance?portfolio_id=' + store.http_portfolio_agg.portfolio[portfolio_id]?.portfolio_meta?.id
  }
    }
}

</script>

<style>
.toggle-checkbox:checked {
  right: 0;
  border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
  background-color: #68D391;
}
.k-line-chart-container {
  display: flex;
  flex-direction: column;
  margin: 1em 0em;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px;
  background-color: #ffffff;
  width: 95%;
  height: 35vw;
  padding: 16px 6px 16px 16px;
}
</style>