<template>
    <div class="flex">
      <button id="dropdownRiskTolButton" data-dropdown-toggle="dropdownRiskTol" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">{{store.selectedItem !== null ? store.selectedItem.label : "Select Risk Tolerance"}} <svg class="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
        </svg></button>
      <!-- Dropdown menu -->
      <div id="dropdownRiskTol" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-60 dark:bg-gray-700 dark:divide-gray-600">
        <ul class="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownRiskTolButton">
          <li v-for="(item, index) in store.data" :key="'generic-checkbox-item' + index">
            <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <div class="flex items-center h-5">
                  <input @change="handleItemClick(item)" :checked="item.value == store.selectedItem?.value ? true : false" aria-describedby="helper-checkbox-text-1" type="radio" name="default-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
              </div>
              <div class="ml-2 text-sm">
                  <label for="helper-checkbox-1" class="font-medium text-gray-900 dark:text-gray-300">
                    <div>{{item.label}}</div>
                    <p id="helper-checkbox-text-1" class="text-xs font-normal text-gray-500 dark:text-gray-300">{{item.description}}</p>
                  </label>
              </div>
            </div>
          </li>
        </ul>
        <div
          :v-if="customData !== undefined"
          class="flex items-center p-3 text-sm font-medium text-green-600 border-t border-gray-200 rounded-b-lg bg-gray-50 dark:border-gray-600 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-green-500 hover:underline">
          <div class="flex items-center h-5">
            <input id="ai-picks-checkbox" aria-describedby="ai-picks-checkbox-text"
              @click="handleCustomItemClick()"
              name="default-radio"
              type="radio"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              :checked="store.selectedItem?.value === 'custom'? true: false"></div>
          <div class="ml-2 text-sm">
            <div class="flex flex-row items-center space-x-2"><label for="ai-picks-checkbox"
                class="font-medium text-gray-900 dark:text-gray-300">Advanced </label><svg
                class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M5 1v3m5-3v3m5-3v3M1 7h7m1.506 3.429 2.065 2.065M19 7h-2M2 3h16a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Zm6 13H6v-2l5.227-5.292a1.46 1.46 0 0 1 2.065 2.065L8 16Z">
                </path>
              </svg></div>
            <div class="relative py-4 space-y-4">
              <div v-for="cItem in Object.keys(customData?.data)" :key="cItem" :name="cItem" class="relative h-10 w-full min-w-[200px]">
                <input text="text"
                  class="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 datepicker-input"
                  placeholder=" "
                  @change="store.customData[cItem].value = $event.target.value"
                  :value="customData?.data[cItem]?.value"><label
                  class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  {{customData?.data[cItem]?.label}}</label></div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'

const store = reactive({
  selectedItem: null,
  data: {
    "low": {
      "label": "Low",
      "value": "low",
      "description": "The stock is likely to keep rising with low risk, making it a good long-term investment with stable growth potential."
    },
    "moderate": {
      "label": "Moderate",
      "value": "moderate",
      "description": "The stock should trend up with some volatility. Moderate risk, suitable for long-term holding, exit when goals are met."
    },
    "high": {
      "label": "High",
      "value": "high",
      "description": "Expect high volatility in the stock, suitable for short-term trading with quick exits when goals are achieved."
    }
  },
  customData: {

  }
})
export default {
 props: {
  customData: Object,
  selectedDefault: String
 },
 components: {
  
  },
  watch: {
    selectedDefault: function(newVal, oldVal) { // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      if(this.selectedDefault){
        if (this.selectedDefault !== 'custom'){
          store.selectedItem = store.data[this.selectedDefault]
        }
      }
    },
    customData: function(newVal, oldVal) { // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      if(this.customData){
        store.customData = this.customData.data
        if (this.customData?.isActive){
          store.selectedItem = {
            "label": "Custom",
            "value": "custom",
            "description": ""
          }
        }
      }
    }
  },
 data(){
     return {store}
 },
 mounted(){
  
 },
 methods: {
  handleItemClick(data){
    store.selectedItem = data
  },
  handleCustomItemClick(){
    store.selectedItem = {
      "label": "Custom",
      "value": "custom",
      "description": ""
    }
  }
 }
}
</script>

<style scoped>

</style>