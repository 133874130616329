<template>
  <div class="flex xl:flex-col md:flex-col lg:flex-col md:flex-col sm:flex-col gap-x-8 gap-y-8 items-center justify-center">
      <!-- BarPot Active --->
      <div class="p-4 md:p-8 lg:py-12 md:transform bg-white rounded-lg md:shadow-md md:hover:shadow-xl md:transition-all md:duration-500 border-2 md:border border-indigo-100">      
        <div class="flex justify-between items-center mb-4 ">
          <h4 class="text-xl lg:text-2xl font-bold">Free</h4>
          <span class="flex flex-col text-xl lg:text-2xl font-bold">
            <span class="text-xl lg:text-2xl font-bold">&dollar;0</span>
          </span>
        </div>
        <div class="flex mb-6">
          <p class="text-sm italic text-[#8c98a4]">No credit card or bank connection required</p>
          <p class="ml-1 text-sm italic text-[#8c98a4]">(Easy Upgrade)</p>
        </div>
        <ul class="text-gray-600 space-y-4 text-lg">
          <li class="flex space-x-2 items-center">
            <div class="w-6 h-6">
              <svg class="text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M5 13l4 4L19 7"></path></svg>
            </div>
            <span>Access to Barpot <strong class="bold">Strategy Trader</strong></span>
          </li>
          <li class="flex space-x-2 items-center">
            <div class="w-6 h-6">
              <svg class="text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M5 13l4 4L19 7"></path></svg>
            </div>
            <span>Unlimited Strategy Backtesting</span>
          </li>
          <li class="flex space-x-2 items-center">
            <div class="w-6 h-6">
              <svg class="text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M5 13l4 4L19 7"></path></svg>
            </div>
            <span>In-Depth Strategy Analytics</span>
          </li>
        </ul>
        <slot />
      </div>
    </div>
</template>

<script>
export default {
 props: {
 },
 mounted(){
 },
 data(){
    return {
    }
 },
 methods: {

 }
 
}
</script>

<style scoped>

</style>