<template>
    <div class="flex justify-center w-full">
      <!-- Chart widget -->
      <div class="flex flex-col w-full col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-gray-200">
          <header class="px-5 py-4 border-b border-gray-100 flex items-center">
              <h2 class="font-semibold text-gray-800 text-xl">Analytics</h2>
          </header>
          <div class="px-5 py-1">
              <div class="flex flex-wrap">
                  <!-- Unique Visitors -->
                  <div class="flex items-center py-2">
                      <div class="mr-5">
                          <div class="flex items-center">
                              <div class="text-3xl font-bold text-gray-800 mr-2">24.7K</div>
                              <div class="text-sm font-medium text-green-500">+49%</div>
                          </div>
                          <div class="text-sm text-gray-500">Unique Visitors</div>
                      </div>
                      <div class="hidden md:block w-px h-8 bg-gray-200 mr-5" aria-hidden="true"></div>
                  </div>
                  <!-- Total Pageviews -->
                  <div class="flex items-center py-2">
                      <div class="mr-5">
                          <div class="flex items-center">
                              <div class="text-3xl font-bold text-gray-800 mr-2">56.9K</div>
                              <div class="text-sm font-medium text-green-500">+7%</div>
                          </div>
                          <div class="text-sm text-gray-500">Total Pageviews</div>
                      </div>
                      <div class="hidden md:block w-px h-8 bg-gray-200 mr-5" aria-hidden="true"></div>
                  </div>
                  <!-- Bounce Rate -->
                  <div class="flex items-center py-2">
                      <div class="mr-5">
                          <div class="flex items-center">
                              <div class="text-3xl font-bold text-gray-800 mr-2">54%</div>
                              <div class="text-sm font-medium text-yellow-500">-7%</div>
                          </div>
                          <div class="text-sm text-gray-500">Bounce Rate</div>
                      </div>
                      <div class="hidden md:block w-px h-8 bg-gray-200 mr-5" aria-hidden="true"></div>
                  </div>
                  <!-- Visit Duration-->
                  <div class="flex items-center">
                      <div>
                          <div class="flex items-center">
                              <div class="text-3xl font-bold text-gray-800 mr-2">2m 56s</div>
                              <div class="text-sm font-medium text-yellow-500">+7%</div>
                          </div>
                          <div class="text-sm text-gray-500">Visit Duration</div>
                      </div>
                  </div>
              </div>
          </div>
          <!-- Chart built with Chart.js 3 -->
          <div class="flex-grow">
              <div id="labels-chart"></div>
          </div>
      </div>
    </div>
</template>

<script>

export default {
 props: {
  defaultData: Object,
  respTimePeriodConfig: Object,
 },
 components: {
  },
 data(){
     return {
     }
 },
 mounted(){
    this.populateChart()
 },
 methods: {
          populateChart() {
    let options = {
        chart: {
          height: 300,
          type: 'area',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        series: [
          {
            name: 'Income',
            data: [18000, 51000, 60000, 38000, 88000, 50000, 40000, 52000, 88000, 80000, 60000, 70000]
          },
          {
            name: 'Outcome',
            data: [27000, 38000, 60000, 77000, 40000, 50000, 49000, 29000, 42000, 27000, 42000, 50000]
          }
        ],
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 2
        },
        grid: {
          strokeDashArray: 2
        },
        fill: {
          type: 'gradient',
          gradient: {
            type: 'vertical',
            shadeIntensity: 1,
            opacityFrom: 0.1,
            opacityTo: 0.8
          }
        },
        xaxis: {
          type: 'category',
          tickPlacement: 'on',
          categories: [
            '25 January 2023',
            '26 January 2023',
            '27 January 2023',
            '28 January 2023',
            '29 January 2023',
            '30 January 2023',
            '31 January 2023',
            '1 February 2023',
            '2 February 2023',
            '3 February 2023',
            '4 February 2023',
            '5 February 2023'
          ],
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            stroke: {
              dashArray: 0
            },
            dropShadow: {
              show: false
            }
          },
          tooltip: {
            enabled: true,
            x: {
          show: false,
        },
          },
          labels: {
            style: {
              colors: '#9ca3af',
              fontSize: '13px',
              fontFamily: 'Inter, ui-sans-serif',
              fontWeight: 400
            },
            formatter: (title) => {
              let t = title;

              if (t) {
                const newT = t.split(' ');
                t = `${newT[0]} ${newT[1].slice(0, 3)}`;
              }

              return t;
            }
          }
        },
        yaxis: {
          labels: {
            align: 'left',
            minWidth: 0,
            maxWidth: 140,
            style: {
              colors: '#9ca3af',
              fontSize: '13px',
              fontFamily: 'Inter, ui-sans-serif',
              fontWeight: 400
            },
            formatter: (value) => value >= 1000 ? `${value / 1000}k` : value
          }
        },
        // tooltip: {
        //   x: {
        //     format: 'MMMM yyyy'
        //   },
        //   y: {
        //     formatter: (value) => `$${value >= 1000 ? `${value / 1000}k` : value}`
        //   },
        //   custom: function (props) {
        //     const { categories } = props.ctx.opts.xaxis;
        //     const { dataPointIndex } = props;
        //     const title = categories[dataPointIndex].split(' ');
        //     const newTitle = `${title[0]} ${title[1]}`;

        //     return {
        //       title: newTitle,
        //       hasTextLabel: true,
        //       wrapperExtClasses: 'min-w-[120px]',
        //       labelDivider: ':',
        //       labelExtClasses: 'ms-2'
        //     }
        //   }
        // },
        responsive: [{
          breakpoint: 568,
          options: {
            chart: {
              height: 300
            },
            labels: {
              style: {
                colors: '#9ca3af',
                fontSize: '11px',
                fontFamily: 'Inter, ui-sans-serif',
                fontWeight: 400
              },
              offsetX: -2,
              formatter: (title) => title.slice(0, 3)
            },
            yaxis: {
              labels: {
                align: 'left',
                minWidth: 0,
                maxWidth: 140,
                style: {
                  colors: '#9ca3af',
                  fontSize: '11px',
                  fontFamily: 'Inter, ui-sans-serif',
                  fontWeight: 400
                },
                formatter: (value) => value >= 1000 ? `${value / 1000}k` : value
              }
            },
          },
        }]
      }

    if (typeof window.ApexCharts !== 'undefined') {
      const chart = new window.ApexCharts(document.getElementById("labels-chart"), options);
      chart.render();
    }
  },
 }
}
</script>

<style scoped>

</style>