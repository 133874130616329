<script setup>
import {defineProps } from 'vue';

import { Handle, Position } from '@vue-flow/core'

const props = defineProps(['label'])
// const toProperCase = (_string) => {
//     return _string.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
// };

// const labelTitlized = toProperCase(props.label.split("-").join(" "))
</script>

<template>
<div>
  <Handle type="target" :position="Position.Right" />

  <div style="padding: 0em">{{ props.label }}</div>
  <Handle type="source" :position="Position.Bottom" />
  </div>
</template>

<style>
.vue-flow__handle-left {
  display: none;
}

.vue-flow__handle-right {
  display: none;
}

.vue-flow__node-standard_node {
  position: absolute;
  padding: 10px;
  border-radius: 3px;
  width: 150px;
  font-size: 12px;
  text-align: center;
  color: var(--vf-node-text);
  cursor: grab;
  transform-origin: 0 0;
  box-sizing: border-box;
  --vf-node-color: #1a192b;
  --vf-handle: var(--vf-node-color);
  --vf-box-shadow: var(--vf-node-color);

  background: var(--vf-node-bg);
  border-color: var(--vf-node-color);
  outline: none;
  border: 1px solid var(--vf-node-color);
  box-shadow: 0 0 0 0.5px var(--vf-box-shadow);
  --vf-node-color: #1a192b;
  --vf-handle: var(--vf-node-color);
  --vf-box-shadow: var(--vf-node-color);
  background: var(--vf-node-bg);
}
</style>