<template>
  <baselineLayout>
      <!-- Breadcrumb -->
      <baselineBreadCrumbs/>
      <div v-if="Object.keys(store.xhrDataPortfolioItem).length <= 0" class="mx-auto fixed inset-0 flex items-center z-10" id="signin-success-message">
        <div class="px-6 py-4 rounded-md text-lg flex flex-col items-center w-full">
          <div class="flex flex-col justify-center text-center items-center">
              <fingerprint-spinner
                  :animation-duration="2000"
                  :size="160"
                  color="#0051d8"
              />
          </div>
          <div id="default-carousel" class="relative w-full 2xl:w-full xl:w-full lg:w-full sm:min-w-[15rem] text-center" data-carousel="slide">
            <!-- Carousel wrapper -->
            <div class="relative overflow-hidden rounded-lg h-16">
                <!-- Item 1 -->
                <div class="hidden duration-700 ease-in-out" data-carousel-item>
                    <span class="text-gray-800">Loading your data...</span>
                </div>
                <!-- Item 2 -->
                <div class="hidden duration-700 ease-in-out" data-carousel-item>
                    <span class="text-gray-800">Counting all the kittens....</span>
                </div>
                <!-- Item 3 -->
                <div class="hidden duration-700 ease-in-out" data-carousel-item>
                    <span class="text-gray-800">There seems to be alot of em!</span>
                </div>
                <!-- Item 4 -->
                <div class="hidden duration-700 ease-in-out" data-carousel-item>
                    <span class="text-gray-800">Still Working on the final touches....</span>
                </div>
                <!-- Item 5 -->
                <div class="hidden duration-700 ease-in-out" data-carousel-item>
                    <span class="text-gray-800">Almost there....</span>
                </div>
            </div>
          </div>
          <progressBar />
        </div>
      </div>
      <div v-if="store.xhrDataPortfolioItem.status === 'portfolio_item.not_public'" class="mx-auto fixed inset-0 flex items-center z-10" id="signin-success-message">
        <div class="px-6 py-4 rounded-md text-lg flex flex-col items-center w-full">
          <div class="flex flex-col justify-center text-center items-center">
              <div class="flex items-center flex-col duration-700 ease-in-out" data-carousel-item>
                    <img src="/images/undraw/undraw_refreshing_beverage_td3r.svg" alt="" class="lg:w-2/4 mb:w-2/4 sm:w-4/5 h-24" style="background-color: transparent;">
                    <span class="text-gray-800">Hmmm, Seems that your not allowed to view this portfolio, Please request access from the creator to view.</span>
                </div>
          </div>
        </div>
      </div>
      
      <div v-if="store.xhrDataPortfolioItem.status !== 'portfolio_item.not_public'" class="2xl:pr-4 xl:pr-4 lg:pr-4 sm:pr-0">
        <div :class=" Object.keys(store.xhrDataPortfolioItem).length <= 0 ? '2xl:ml-72 xl:ml-72 lg:ml-72 sm:ml-0 h-full p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 bg-gray-100 overflow-x-hidden blur-state-loading': '2xl:ml-72 xl:ml-72 lg:ml-72 sm:ml-0 h-full p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 bg-gray-100 overflow-x-hidden'">
            <div v-for="(warningAlert, warningAlertIdx) in store.xhrDataPortfolioItem?.agg?.portfolio[store.xhrDataPortfolioItem?.id]?.status?.items" :key="'warningAlert_' + warningAlertIdx" class="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300" role="alert">
              <span class="font-medium">Warning!</span> {{warningAlert.message}}
            </div>
            <div class="flex 2xl:flex-row xl:flex-row lg:flex-row sm:flex-col px-4 justify-between items-center">
                <div class="flex px-4 sm:px-6 items-center md:space-x-24">
                  <h1 class="flex flex-col mb-4 text-md font-extrabold text-gray-900 dark:text-white md:text-lg lg:text-lg">
                    <span class="px-4 bg-yellow-100 w-fit text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300">Creator: {{store.xhrDataPortfolioItem?.user?.username}}</span>
                    <span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">{{store.xhrDataPortfolioItem?.name}}</span>
                  </h1>
                    <!-- <div v-if="store.userData.subscription_status == 'subscription.active'" class="bg-yellow-100 border border-yellow-400 text-blue-700 text-center px-2 py-1 rounded relative" role="alert">
                      <strong class="font-bold">You are a Premium Member!</strong>
                    </div>
                    <div v-if="store.userData.subscription_status == 'subscription.null'" class="flex flex-col bg-red-100 border border-yellow-400 text-blue-700 text-center px-2 py-3 rounded relative" role="alert">
                      <strong class="font-bold">You are not a Premium Member!</strong>
                      <span class="font-sm italic">Some alerts will be blurred below!</span>
                      <a href="/account" class="underline">Subscribe to a premium account here!</a>
                    </div> -->
                </div>
                
                <div class="flex flex-col items-center">
                  <div v-if="store.userData?.info?.username == 'simple'" class="flex">
                      <div class="relative w-full">
                          <input type="number" id="investment-input" class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-s-lg border-e-gray-50 border-e-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-e-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" value="10000" placeholder="$10,000" required>
                      </div>
                      <button id="investment-apply-button" @click="handlePaperInvestment()" class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-e-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600" type="button">
                          <span class="material-symbols-outlined text-sm">attach_money</span>
                          INVEST
                      </button>
                  </div>
                  <div class="flex items-center mx-4 rounded-md shadow-sm h-fit" role="group">
                    <button data-tooltip-target="portfolio-notification-toogle-tooltip" type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-l-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
                      <label class="relative inline-flex items-center cursor-pointer mr-4">
                        <input type="checkbox" ref="publicViewingToggle" @click="handlePublicStatusPortfolioItem($event)" value="" class="sr-only peer" :checked="store.xhrDataPortfolioItem?.user?.is_public">
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label>
                      Public Viewing
                    </button>
                    <div id="portfolio-notification-toogle-tooltip" role="tooltip" class="absolute z-40 w-2/6 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 opacity-0 invisible" style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(478.545px, -1328px, 0px);" data-popper-placement="top">Allow this portfolio to be shareable to other visitors and users of Barpot.<div data-v-2e83651c="" class="tooltip-arrow" data-popper-arrow="" style="position: absolute; left: 0px; transform: translate3d(58.1818px, 0px, 0px);"></div></div>
                    
                    <button @click="copyNavLink()" data-tooltip-target="portfolio-share-status-tooltip" type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border-t border-b border-gray-900 hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
                      <span class="material-symbols-outlined mr-2">share</span>
                      Share Link
                    </button>
                    <div id="portfolio-share-status-tooltip" role="tooltip" class="absolute z-40 w-2/6 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 opacity-0 invisible" style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(478.545px, -1328px, 0px);" data-popper-placement="top">A link to share your portfolio w/ others. <div data-v-2e83651c="" class="tooltip-arrow" data-popper-arrow="" style="position: absolute; left: 0px; transform: translate3d(58.1818px, 0px, 0px);"></div></div>
                    
                    <button @click="handleEditButton()" type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-r-md hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
                      <span class="material-symbols-outlined mr-2">edit</span>
                       Edit Portfolio
                    </button>
                  </div>
                </div>

            </div>

            
            
            <div class="flex space-x-8 items-center justify-center rounded dark:bg-gray-800">
              <BacktestChart :propData="store.xhrDataPortfolioItem"/>
            </div>
            <div v-if="window?.screen?.width <= 700" class="flex flex-row w-[auto] 2xl:w-[auto] xl:w-[auto] lg:w-[94%] justify-start 2xl:justify-start xl:justify-start lg:justify-center p-6 mb-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              <div id="alert-additional-content-5" class="p-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-gray-800" role="alert">
                <div class="flex items-center">
                  <svg class="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                  </svg>
                  <span class="sr-only">Info</span>
                  <h3 class="text-lg font-medium text-gray-800 dark:text-gray-300 italic">Please use a desktop/laptop device for the best performance while using our platform</h3>
                </div>
              </div>
            </div>
            <div class="flex flex-row w-[auto] 2xl:w-[auto] xl:w-[auto] lg:w-[94%] justify-start 2xl:justify-start xl:justify-start lg:justify-center p-6 mb-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              <div id="alert-additional-content-5" class="p-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-gray-800" role="alert">
                <div class="flex items-center">
                  <svg class="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                  </svg>
                  <span class="sr-only">Info</span>
                  <h3 class="text-lg font-medium text-gray-800 dark:text-gray-300">This backtesting data was generated with historical data</h3>
                </div>
                <div class="mt-2 mb-4 text-sm text-gray-800 dark:text-gray-300 italic">
                  While using this portfolio/strategy in any form it may include ETFs, which includes the risk associated to trading/holding and any cost of an ETF(s) before investing you should personally perform your own due-diligence on each securities/stock(s)/ETF(s) using relevant (prospectus, information) before proceeding with any information given by our website. <a href="/disclaimer" class="flex items-center underline">
                          <h5 class="text-base tracking-tight text-gray-900 dark:text-white italic">Disclaimer</h5>
                      </a>
                </div>
              </div>
            </div>
            

  <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
      <ul class="flex flex-wrap -mb-px text-sm font-medium text-center" id="default-styled-tab" data-tabs-toggle="#default-styled-tab-content" data-tabs-active-classes="text-purple-600 hover:text-purple-600 dark:text-purple-500 dark:hover:text-purple-500 border-purple-600 dark:border-purple-500" data-tabs-inactive-classes="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300" role="tablist">
          <li class="me-2" role="presentation">
              <button class="flex p-4 border-gray-300 border-b-1 hover:border-b-2 rounded-t-lg" id="profile-styled-tab" data-tabs-target="#styled-profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Backtest Simulation</button>
          </li>
          <li class="me-2" role="presentation">
              <button class="flex flex-row p-4 border-gray-300 border-b-1 hover:border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300" id="dashboard-styled-tab" data-tabs-target="#styled-dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="false">
                Active Trading
                <span class="ml-1">
                  <span v-if="store.xhrDataPortfolioItem?.investment?.trade_journal?.trading_environment == 'sandbox'" class="bg-yellow-100 text-yellow-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">Paper</span>
                </span>
              </button>
          </li>
      </ul>
  </div>
  <div id="default-styled-tab-content">
      <div class="p-4 rounded-lg bg-gray-50 dark:bg-gray-800" id="styled-profile" role="tabpanel" aria-labelledby="profile-tab">
          <div data-v-2e83651c="" class="flex items-center justify-center w-full"><hr data-v-2e83651c="" class="w-full h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"><span data-v-2e83651c="" class="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">Backtest Simulation</span></div>
          <div class="flex flex-row w-[auto] 2xl:w-[auto] xl:w-[auto] lg:w-[94%] justify-start 2xl:justify-start xl:justify-start lg:justify-center p-6 mb-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              <div class="flex 2xl:flex-row xl:flex-row lg:flex-col sm:flex-col">
                <div class="flex flex-col">
                  <h5 data-tooltip-target="simulated-return-tooltip" class="ml-2 mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Simulated Returns</h5>
                  <div id="simulated-return-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                    Hypothetical investment performance created through modeling, used for strategy testing and risk assessment without actual capital involvement.
                    <div class="tooltip-arrow" data-popper-arrow></div>
                  </div>
                  <div class="flex 2xl:flex-col xl:flex-col lg:flex-row lg:divide-y">
                    <div class="flex flex-col px-2">
                      <a href="#" class="flex flex-row items-center" data-tooltip-target="initial-value-tooltip">
                          <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Initial Value</h5>
                          <span class="material-symbols-outlined text-sm mx-1">info</span>
                      </a>
                      <div id="initial-value-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        Starting cash amount in your simulated portfolio, serving as a reference for measurements during simulations.
                        <div class="tooltip-arrow" data-popper-arrow></div>
                      </div>
                      <p class="mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white">${{ new Number(store.xhrDataPortfolioItem?.simulation?.initial_investment).toLocaleString()}}</p>
                    </div>
                    
                    <div class="flex flex-col px-2">
                      <a href="#" class="flex flex-row items-center" data-tooltip-target="max-drawdown-percent-tooltip">
                          <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Max Drawdown</h5>
                          <span class="material-symbols-outlined text-sm mx-1">info</span>
                      </a>
                      <div id="max-drawdown-percent-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        The maximum percent loss or decline in value experienced by an investment or portfolio from its peak to its lowest point.
                        <div class="tooltip-arrow" data-popper-arrow></div>
                      </div>
                      <p class="mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white">{{ new Number(store.xhrDataPortfolioItem?.simulation?.calculated_metrics?.drawdown?.max_drawdown_pct).toLocaleString()}}%</p>
                    </div>

                    <!-- <div class="flex flex-col px-2">
                      <a href="#" class="flex flex-row items-center" data-tooltip-target="max-drawdown-value-tooltip">
                          <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Max Drawdown Value</h5>
                          <span class="material-symbols-outlined text-sm mx-1">info</span>
                      </a>
                      <div id="max-drawdown-value-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        The maximum value loss or decline in value experienced by an investment or portfolio from its peak to its lowest point.
                        <div class="tooltip-arrow" data-popper-arrow></div>
                      </div>
                      <p class="mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white">{{ new Number(store.xhrDataPortfolioItem?.simulation?.calculated_metrics?.drawdown?.max_drawdown).toLocaleString()}}</p>
                    </div> -->
                  </div>
                </div>
                <hr class="h-full border-solid border border-gray mx-4" style="height: inherit;">
                <div class="flex flex-col">
                  <h5 class="ml-2 mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Metrics</h5>
                  <div class="flex flex-row divide-x 2xl:flex-row xl:flex-row lg:flex-col sm:flex-col">
                    <div class="flex flex-col px-2 w-48">
                      <a href="#" class="flex flex-row items-center" data-tooltip-target="cumulative-return-tooltip">
                          <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Cumulative Return %</h5>
                          <span class="material-symbols-outlined text-sm mx-1">info</span>
                      </a>
                      <div id="cumulative-return-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        The total percentage change in the value of your portfolio over a specified period, including all gains and losses.
                        <div class="tooltip-arrow" data-popper-arrow></div>
                      </div>
                      <p :class="store.xhrDataPortfolioItem?.simulation?.calculated_metrics?.cumulative_return_pct > 0 ? 'w-fit mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white bg-green-300': 'w-fit mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white bg-red-300'">
                        {{ new Number(store.xhrDataPortfolioItem?.simulation?.calculated_metrics?.cumulative_return_pct) > 0 ? '+': ''}}{{new Number(store.xhrDataPortfolioItem?.simulation?.calculated_metrics?.cumulative_return_pct).toLocaleString()}}%</p>
                    </div>
                    
                    <div class="flex flex-col px-2 w-48">
                      <a href="#" class="flex flex-row items-center" data-tooltip-target="cumulative-value-tooltip">
                          <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Cumulative Value</h5>
                          <span class="material-symbols-outlined text-sm mx-1">info</span>
                      </a>
                      <div id="cumulative-value-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">            
                        The total value of an portfolio over the simulated time, including all gains and losses.
                        <div class="tooltip-arrow" data-popper-arrow></div>
                      </div>
                      <p :class="store.xhrDataPortfolioItem?.simulation?.calculated_metrics?.cumulative_return_pct > 0 ? 'w-fit mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white bg-green-300': 'w-fit mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white bg-red-300'">${{ new Number(store.xhrDataPortfolioItem?.simulation?.calculated_metrics?.cumulative_value).toLocaleString()}}</p>
                    </div>
                  </div>

                  <div class="flex flex-row divide-x 2xl:flex-row xl:flex-row lg:flex-col sm:flex-col">
                    <div class="flex flex-col px-2 w-48">
                      <a href="#" class="flex flex-row items-center" data-tooltip-target="average-winrate-tooltip">
                          <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Avg. Individualized Model Winrate</h5>
                          <span class="material-symbols-outlined text-sm mx-1">info</span>
                      </a>
                      <div id="average-winrate-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        The average success rate of individualized models within your portfolio that achieves their goals or outcomes.
                        <div class="tooltip-arrow" data-popper-arrow></div>
                      </div>
                      <p class="mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white">{{ new Number(store.xhrDataPortfolioItem?.simulation?.calculated_metrics?.average_winrate).toLocaleString()}}%</p>
                    </div>

                    <div class="flex flex-col px-2 w-48">
                      <a href="#" class="flex flex-row items-center" data-tooltip-target="average-confidence-tooltip">
                          <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Avg. Individualized Model Confidence</h5>
                          <span class="material-symbols-outlined text-sm mx-1">info</span>
                      </a>
                      <div id="average-confidence-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        The average level of certainty/trustworthiness exhibited by individualized models attempting to achieve their predictions.
                        <div class="tooltip-arrow" data-popper-arrow></div>
                      </div>
                      <p class="mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white">{{ new Number(store.xhrDataPortfolioItem?.simulation?.calculated_metrics?.average_confidence).toLocaleString()}}%</p>
                    </div>
                  </div>
                </div>
                <div class="flex flex-row divide-x sm:mt-4">
                    <div class="flex bg-white rounded-lg shadow dark:bg-gray-800 p-2 md:p-4 2xl:flex-row xl:flex-row lg:flex-col sm:flex-col 2xl:bg-white xl:bg-white lg:shadow 2xl:shadow xl:shadow lg:shadow sm:bg-transparent sm:shadow-none">
                      
                      <div class="flex hidden justify-between mb-3">
                          <div class="flex justify-center items-center">
                              <h5 class="text-xl font-bold leading-none text-gray-900 dark:text-white pr-1">Simulated Winrate</h5>
                              <svg data-popover-target="chart-info" data-popover-placement="bottom" class="w-3.5 h-3.5 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white cursor-pointer ml-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm0 16a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm1-5.034V12a1 1 0 0 1-2 0v-1.418a1 1 0 0 1 1.038-.999 1.436 1.436 0 0 0 1.488-1.441 1.501 1.501 0 1 0-3-.116.986.986 0 0 1-1.037.961 1 1 0 0 1-.96-1.037A3.5 3.5 0 1 1 11 11.466Z"/>
                              </svg>
                              <div data-popover id="chart-info" role="tooltip" class="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400">
                                  <div class="p-3 space-y-2">
                                      <h3 class="font-semibold text-gray-900 dark:text-white">Activity growth - Incremental</h3>
                                      <p>Report helps navigate cumulative growth of community activities. Ideally, the chart should have a growing trend, as stagnating chart signifies a significant decrease of community activity.</p>
                                      <h3 class="font-semibold text-gray-900 dark:text-white">Calculation</h3>
                                      <p>For each date bucket, the all-time volume of activities is calculated. This means that activities in period n contain all activities up to period n, plus the activities generated by your community in period.</p>
                                      <a href="#" class="flex items-center font-medium text-blue-600 dark:text-blue-500 dark:hover:text-blue-600 hover:text-blue-700 hover:underline">Read more <svg class="w-2 h-2 ml-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
                                </svg></a>
                                  </div>
                                  <div data-popper-arrow></div>
                              </div>
                            </div>
                          <div>
                            <button type="button" data-tooltip-target="data-tooltip" data-tooltip-placement="bottom" class="hidden sm:inline-flex items-center justify-center text-gray-500 w-8 h-8 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm"><svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"/>
                      </svg><span class="sr-only">Download data</span>
                            </button>
                            <div id="data-tooltip" role="tooltip" class="hidden absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                Download CSV
                                <div class="tooltip-arrow" data-popper-arrow></div>
                            </div>
                          </div>
                      </div>

                      <div class="hidden">
                        <div class="flex" id="devices">
                          <div class="flex items-center mr-4">
                              <input id="desktop" type="checkbox" value="desktop" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                              <label for="desktop" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Desktop</label>
                          </div>
                          <div class="flex items-center mr-4">
                              <input id="tablet" type="checkbox" value="tablet" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                              <label for="tablet" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Tablet</label>
                          </div>
                          <div class="flex items-center mr-4">
                              <input id="mobile" type="checkbox" value="mobile" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                              <label for="mobile" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Mobile</label>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col">
                        <div class="flex flex-col px-2 w-max">
                          <a href="#" class="flex flex-row items-center" data-tooltip-target="win-rate-tooltip">
                              <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Win Rate</h5>
                              <span class="material-symbols-outlined text-sm mx-1">info</span>
                          </a>
                          <div id="win-rate-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                            The percentage of successful wins (to losses) in your current portfolio.
                            <div class="tooltip-arrow" data-popper-arrow></div>
                          </div>
                          <p class="mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white">{{ new Number(store.xhrDataPortfolioItem?.agg?.portfolio[store.xhrDataPortfolioItem?.id]?.trades_analysis?.winrate).toFixed(2).toLocaleString()}}%</p>
                        </div>

                        <div class="flex flex-col px-2 w-max">
                          <a href="#" class="flex flex-row items-center" data-tooltip-target="win-rate-w-open-tooltip">
                              <div class="flex flex-col">
                                <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Win Rate</h5>
                                <h5 class="text-base tracking-tight text-gray-900 dark:text-white italic">(w/ Opens Trades)</h5>
                              </div>
                              <span class="material-symbols-outlined text-sm mx-1">info</span>
                          </a>
                          <div id="win-rate-w-open-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                            The percentage of successful wins (to losses and open trades) in your current portfolio.
                            <div class="tooltip-arrow" data-popper-arrow></div>
                          </div>
                          <p class="mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white">{{ new Number(store.xhrDataPortfolioItem?.agg?.portfolio[store.xhrDataPortfolioItem?.id]?.trades_analysis?.winrate_w_opens).toFixed(2).toLocaleString()}}%</p>
                        </div>

                        <div class="flex flex-col px-2 w-max">
                          <a href="#" class="flex flex-row items-center" data-tooltip-target="total-trade-tooltip">
                              <div class="flex flex-col">
                                <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Total Trades</h5>
                              </div>
                              <span class="material-symbols-outlined text-sm mx-1">info</span>
                          </a>
                          <div id="total-trade-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                            The overall count of trades executed within your selected timeframe for your portfolio.
                            <div class="tooltip-arrow" data-popper-arrow></div>
                          </div>
                          <p class="mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white">{{ new Number(store.xhrDataPortfolioItem?.simulation?.calculated_metrics?.total_trades).toLocaleString()}}</p>
                        </div>
                      </div>

                      <!-- Line Chart -->
                      <div class="h-full w-full 2xl:min-w-[20rem] xl:min-w-[20rem] lg:min-w-[20rem] sm:min-w-[15rem]" id="donut-chart"></div>
                    </div>
                </div>
              </div>
            </div>

            <div class="flex flex-col w-[auto] 2xl:w-[auto] xl:w-[auto] lg:w-[94%] p-6 mb-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              <div data-v-2e83651c="" class="flex flex-col mb-4">
                <div class="flex flex-row">
                  <h5 data-v-2e83651c="" data-tooltip-target="portfolio-structure-tooltip" class="w-fit text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Portfolio Structure <span data-v-2e83651c="" class="material-symbols-outlined text-sm mx-0.5">info</span></h5>
                  <div data-v-2e83651c="" id="portfolio-structure-tooltip" role="tooltip" class="absolute z-40 w-2/6 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 opacity-0 invisible" data-popper-placement="top" style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(367.879px, -1273.94px, 0px);"> A visual representation of your portfolios configurations of how your portfolio is aggregated. <div data-v-2e83651c="" class="tooltip-arrow" data-popper-arrow="" style="position: absolute; left: 0px; transform: translate3d(44.8485px, 0px, 0px);"></div></div>
                  <hr data-v-2e83651c="" class="h-full border-solid border border-gray mx-4" style="height: inherit;">
                  <p class="self-center text-gray-600 italic">How the portfolio works</p>
                </div>
              </div>
              <div v-if="Object.keys(store.xhrDataPortfolioItem).length >= 0" class="flex flex-col h-full w-full">
                <breakdownFlowPortfolioTrader :portfolioItemHttpRequestData="store.xhrDataPortfolioItem"/>
              </div>
            </div>
            
            <div class="flex 2xl:flex-row xl:flex-row lg:flex-col sm:flex-col lg:space-x-0 2xl:space-x-8 xl:space-x-8 items-start justify-center rounded dark:bg-gray-800">
                <div class="w-8/12 2xl:w-8/12 xl:w-8/12 lg:w-full sm:w-full">
                    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white ">
                      <div class="rounded-t mb-0 px-4 py-3 border-0">
                        <div class="flex flex-wrap items-center">
                          <div class="ml-2 relative w-full max-w-full flex-grow flex-1">
                            <h3 class="font-semibold text-base text-blueGray-700">
                              Alert Allocation
                            </h3>
                          </div>
                          <div class="relative hidden w-full px-4 max-w-full flex-grow flex-1 text-right">
                            <button class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                              See all
                            </button>
                          </div>
                        </div>
                      </div>
                      
                      <div class="block w-full overflow-x-auto h-96 mostly-customized-scrollbar">
                        <table class="items-center w-full border-collapse text-blueGray-700  ">
                          <thead class="thead-light ">
                            <tr>
                              <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                Stock
                              </th>
                              <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                Trades
                              </th>
                              <th class="px-6 bg-blueGray-50 text-blueGray-700 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px">
                                Allocation (⪅)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="stock_ind in store.xhrDataPortfolioItem?.agg?.portfolio?.[store.xhrDataPortfolioItem?.id]?.stock_symbols" :key="stock_ind.symbol">
                              <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                <a class="underline" :href="'/stock?symbol=' + stock_ind.symbol">{{stock_ind.symbol}}</a>
                              </th>
                              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                                {{stock_ind.total_trades}}
                              </td>
                              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                <div class="flex items-center">
                                  <span class="mr-2">{{(stock_ind.orderbook_weight).toFixed(3)}}%</span>
                                  <div class="relative w-full">
                                    <div class="overflow-hidden h-2 text-xs flex rounded bg-red-200">
                                      <div :style="'width: ' + stock_ind.orderbook_weight + '%'" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"></div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="w-8/12 2xl:w-8/12 xl:w-8/12 lg:w-full sm:w-full">
                  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                    <div class="rounded-t mb-0 px-4 py-3 border-0">
                          <div class="flex flex-wrap items-center">
                            <div class="ml-2 relative w-full max-w-full flex-grow flex-1">
                              <h3 class="font-semibold text-base text-blueGray-700">
                                Eligible Alerts
                              </h3>
                            </div>
                            <div class="hidden relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                              <button class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                See all
                              </button>
                            </div>
                          </div>
                        </div>
                  
                    <div class="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded bg-white">
                    <div class="h-96 mostly-customized-scrollbar overflow-x-auto rounded-lg shadow md:block bg-white">
                    <table class="w-full">
                      <thead class="bg-gray-50 border-b-2 border-gray-200">
                      <tr>
                        <th class="w-20 p-3 text-sm font-semibold tracking-wide text-left">Symbol</th>
                        <th class="p-3 text-sm font-semibold tracking-wide text-left">Winrate (100%)</th>
                        <th class="p-3 text-sm font-semibold tracking-wide text-left">AI Confidence (100%)</th>
                        <th class="p-3 text-sm font-semibold tracking-wide text-left">Entry Date</th>
                        <th class="w-24 p-3 text-sm font-semibold tracking-wide text-left">Exit Date</th>
                        <th class="w-24 p-3 text-sm font-semibold tracking-wide text-left">Entry Price</th>
                        <th class="w-32 p-3 text-sm font-semibold tracking-wide text-left">Exit Price</th>
                        <th class="w-32 p-3 text-sm font-semibold tracking-wide text-left">Return Percent</th>
                      </tr>
                      </thead>
                      <tbody class="divide-y divide-gray-100">

                      <tr class="bg-white" v-for="single_trade in store.xhrDataPortfolioItem?.agg?.portfolio?.[store.xhrDataPortfolioItem?.id].data.slice().reverse()" :key="single_trade?.id">
                        <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                          <a :href="'/stock?symbol=' + single_trade?.symbol"> <span class="p-1.5 text-xs underline font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">{{single_trade?.symbol}}</span></a>
                        </td>
                        <td class="p-3 text-sm text-gray-700 whitespace-nowrap">{{(single_trade?.dynamic_model_metrics?.winrate_precent).toFixed(2)}}%</td>
                        <td class="p-3 text-sm text-gray-700 whitespace-nowrap">{{(single_trade?.neural_model_section_confidence).toFixed(2)}}%</td>
                        <td class="p-3 text-sm text-gray-700 whitespace-nowrap">{{single_trade?.entrytime}}</td>
                        <td class="p-3 text-sm text-gray-700 whitespace-nowrap">{{single_trade?.exittime ? new Date(single_trade?.exittime).toLocaleString() : ""}}
                          <span v-if="single_trade?.exittime == null" class="bg-indigo-100 text-indigo-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-indigo-400 border border-indigo-400">Pending To Exit</span>
                        </td>
                        <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                          <span class="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">${{ new Number(single_trade?.entryprice).toLocaleString()}}</span>
                        </td>
                        <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                          <span class="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">{{ single_trade?.exittime ? "$" + new Number(single_trade?.exitprice).toLocaleString(): "..."}}</span>
                        </td>
                        <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                          <span :class="this.isPositive(single_trade?.returnpct, 3)">{{ single_trade?.exittime ? new Number(single_trade?.returnpct).toLocaleString() + "%": "..."}}</span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    </div>
                    </div>
                </div>
              </div>
            </div>
      </div>
      <div class="p-4 rounded-lg bg-gray-50 dark:bg-gray-800" id="styled-dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
            <div v-if="store.userData?.info?.username == 'simple'" class="flex items-center justify-center w-full">
              <hr class="w-full h-px my-8 bg-gray-200 border-0 dark:bg-gray-700">
              <span class="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">Paper Trading</span>
            </div>
            <metricInformaticsPaperTab v-if="store.userData?.info?.username == 'simple'" />
            <div v-if="store.userData?.info?.username == 'simple'" class="flex 2xl:flex-row xl:flex-row lg:flex-col sm:flex-col lg:space-x-0 2xl:space-x-8 xl:space-x-8 items-start justify-center rounded dark:bg-gray-800">
                <div class="w-8/12 2xl:w-8/12 xl:w-8/12 lg:w-full sm:w-full">
                    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white ">
                      <div class="rounded-t mb-0 px-4 py-3 border-0">
                        <div class="flex flex-wrap items-center">
                          <div class="ml-2 relative w-full max-w-full flex-grow flex-1">
                            <h3 class="font-semibold text-base text-blueGray-700">
                              Trade Journal
                            </h3>
                          </div>
                        </div>
                      </div>
                      


                      <div class="block w-full overflow-x-auto h-96 mostly-customized-scrollbar">
                        <table class="items-center w-full border-collapse text-blueGray-700  ">
                          <thead class="thead-light ">
                            <tr>
                              <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                Stock
                              </th>
                              <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                Trades
                              </th>
                              <th class="px-6 bg-blueGray-50 text-blueGray-700 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px">
                                P/L (%)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <!-- eslint-disable-next-line vue/no-v-for-template-key -->
                            <template v-for="journal_asset in store.xhrDataPortfolioItem?.investment?.trade_journal?.breakdown" :key="journal_asset?.id">
                              <tr @click="handleTableShowNestedTradeJournal(journal_asset?.symbol)" class="">
                                <th :id="journal_asset?.symbol + '_table_accordion'" class="table-accordion border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                  <a class="underline" :href="'/stock?symbol=' + journal_asset?.symbol">{{journal_asset?.symbol}}</a>
                                </th>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                                  {{journal_asset?.orders?.length}}
                                </td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                  <div class="flex items-center">
                                    <span class="mr-2">{{ journal_asset?.percent_return_cumulative }}%</span>
                                    <div class="relative w-full">
                                      <div class="overflow-hidden h-2 text-xs flex rounded bg-red-200">
                                        <div :style="'width: ' + 0 + '%'" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
                                      </div>
                                    </div>
                                    <span class="mr-2">{{ journal_asset?.value_return_cumulative }}%</span>
                                  </div>
                                </td>
                              </tr>
                              <tr :id="journal_asset?.symbol + '_table_banner'" class="hidden">
                                <td colspan="6" class="pl-10">
                                  <h1 class="text-xl block">Trade Log</h1>
                                </td>
                              </tr>
                              <tr :id="journal_asset?.symbol + '_table_content'" colspan="3" class="hidden w-full overflow-hidden transition-[max-height] delay-1000 duration-1000 ease-in-out  max-h-20">
                                <td colspan="3" class="pl-10">
                                  <table class="w-full block mx-auto">
                                    <thead class="bg-[#222E3A]/[6%] rounded-lg text-base text-white font-semibold w-full">
                                      <th
                                        class="py-3 px-4 text-[#212B36] text-base sm:text-sm font-normal whitespace-nowrap rounded-l-lg">
                                        Created At</th>
                                      <th
                                        class="py-3 px-4 text-[#212B36] text-base sm:text-sm font-normal whitespace-nowrap">
                                        P/L (%)</th>
                                      <th
                                        class="py-3 px-4 text-[#212B36] text-base sm:text-sm font-normal whitespace-nowrap">
                                        Type</th>
                                      <th
                                        class="py-3 px-4 text-[#212B36] text-base sm:text-sm font-normal whitespace-nowrap rounded-r-lg">
                                        NA</th>
                                    </thead>
                                    <tbody>
                                      <tr v-for="asset_order in journal_asset?.orders" class=" border bg-gray-100 ml-4" :key="asset_order.id">
                                        <td class="py-3 px-4">{{asset_order?.created_at}}</td>
                                        <td class="py-3 px-4">{{asset_order?.analysis?.sell_percent_return_change}}</td>
                                        <td class="py-3 px-4 text-center">{{asset_order?.analysis?.order_type}}</td>
                                        <td class="py-3 px-4 text-center">...</td>
                                      </tr>
      
                                    </tbody>
                                  </table>
                                  <!-- <div v-for="asset_order in journal_asset?.orders" class=" border bg-gray-100 ml-4" :key="asset_order.id">
                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                      </td>
                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{asset_order?.created_at}}</td>
                                    <td
                                      class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                      <div class="flex items-center"><span
                                          class="mr-2">{{asset_order?.analysis?.sell_percent_return_change}}</span>
                                      </div>
                                    </td>
                                  </div> -->
                                </td>
                              </tr>

                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="w-6/12 2xl:w-6/12 xl:w-6/12 lg:w-full sm:w-full">
                    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                      <div class="rounded-t mb-0 px-4 py-3 border-0">
                            <div class="flex flex-wrap items-center">
                              <div class="ml-2 relative w-full max-w-full flex-grow flex-1">
                                <h3 class="font-semibold text-base text-blueGray-700">
                                  Trading History (Recent)
                                </h3>
                              </div>
                              <div class="hidden relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                                <button class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                  See all
                                </button>
                              </div>
                            </div>
                          </div>
                    
                      <div class="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded bg-white">
                      <div class="h-[31.5em] mostly-customized-scrollbar overflow-x-auto rounded-lg shadow md:block bg-white">
                      <table class="w-full">
                        <thead class="bg-gray-50 border-b-2 border-gray-200">
                        <tr>
                          <th class="w-20 p-3 text-sm font-semibold tracking-wide text-left">Symbol</th>
                          <th class="p-3 text-sm font-semibold tracking-wide text-left">Order Type</th>
                          <th class="p-3 text-sm font-semibold tracking-wide text-left">Quantity</th>
                          <th class="p-3 text-sm font-semibold tracking-wide text-left">Submit Date</th>
                          <th class="w-24 p-3 text-sm font-semibold tracking-wide text-left">Fill Date</th>
                          <th class="w-24 p-3 text-sm font-semibold tracking-wide text-left">Avg Fill Price</th>
                          <th class="w-32 p-3 text-sm font-semibold tracking-wide text-left">Time In Force</th>
                          <th class="w-32 p-3 text-sm font-semibold tracking-wide text-left">Order Status</th>
                        </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-100">
                          <tr class="bg-white" v-for="single_trade in agg_orders_from_breakdown(store.xhrDataPortfolioItem?.investment?.trade_journal?.breakdown)" :key="single_trade?.id">
                            <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                              <a :href="'/stock?symbol=' + single_trade?.symbol"> <span class="p-1.5 text-xs underline font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">{{single_trade?.symbol}}</span></a>
                            </td>
                            <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                              <span class="bg-gray-500 text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-indigo-400 border border-indigo-400">{{single_trade?.side}}</span>
                              {{(single_trade?.order_type)}}</td>
                            <td class="p-3 text-sm text-gray-700 whitespace-nowrap">{{(single_trade?.qty)}}</td>
                            <td class="p-3 text-sm text-gray-700 whitespace-nowrap">{{single_trade?.submitted_at}}</td>
                            <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                              <span class="bg-indigo-100 text-indigo-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-indigo-400 border border-indigo-400">{{single_trade?.filled_at}}</span>
                            </td>
                            <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                              <span class="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">${{ new Number(single_trade?.filled_avg_price).toLocaleString()}}</span>
                            </td>
                            
                            <td class="p-3 text-sm text-gray-700 whitespace-nowrap">{{single_trade?.time_in_force}}</td>
                            <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                              <span class="bg-indigo-100 text-indigo-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-indigo-400 border border-indigo-400">{{single_trade?.status}}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                      </div>
                </div>
              </div>
            </div>
      </div>
  </div>
        </div>
      </div>
  </baselineLayout>
</template>

<script>
import BacktestChart from "./chart/BacktestChart.vue";
import baselineLayout from './components/baselineLayout.vue'
import baselineBreadCrumbs from './components/baselineBreadCrumbs.vue'
import breakdownFlowPortfolioTrader from './components/breakdownFlowPortfolioTrader.vue'
import metricInformaticsPaperTab from './components/metricInformaticsPaperTab'
import progressBar from './components/progressBar.vue'
import { FingerprintSpinner } from 'epic-spinners';
import { initFlowbite } from 'flowbite'
import { Tabs } from 'flowbite';

import _ from 'lodash'

import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import { store } from './stores/PageBotTraderInstanceStore'

const $toast = useToast();

let currPath = new URL(window.location.href);
export default {
  name: 'PageBotTraderInstance',
  components: {
    baselineLayout,
    BacktestChart,
    baselineBreadCrumbs,
    FingerprintSpinner,
    breakdownFlowPortfolioTrader,
    progressBar,
    metricInformaticsPaperTab
  },
  data () {
      return {store}
  },
  mounted(){
      initFlowbite();
      this.refreshToken();
      this.getUser();
      setInterval(() => {
        console.log("Auto JWT refresh");
        this.refreshToken();
      }, 60000);

      this.getPortfolio();
      initFlowbite();


      // Tabs
      const tabsElement = document.getElementById('tabs-example');

// create an array of objects with the id, trigger element (eg. button), and the content element
const tabElements = [
    {
        id: 'profile',
        triggerEl: document.querySelector('#profile-tab-example'),
        targetEl: document.querySelector('#profile-example'),
    },
    {
        id: 'dashboard',
        triggerEl: document.querySelector('#dashboard-tab-example'),
        targetEl: document.querySelector('#dashboard-example'),
    },
    {
        id: 'settings',
        triggerEl: document.querySelector('#settings-tab-example'),
        targetEl: document.querySelector('#settings-example'),
    },
    {
        id: 'contacts',
        triggerEl: document.querySelector('#contacts-tab-example'),
        targetEl: document.querySelector('#contacts-example'),
    },
];

// options with default values
const options = {
    defaultTabId: 'settings',
    activeClasses:
        'text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-400 border-blue-600 dark:border-blue-500',
    inactiveClasses:
        'text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300',
    onShow: () => {
        console.log('tab is shown');
    },
};

// instance options with default values
const instanceOptions = {
  id: 'tabs-example',
  override: true
};
/*
* tabsElement: parent element of the tabs component (required)
* tabElements: array of tab objects (required)
* options (optional)
* instanceOptions (optional)
*/
const tabs = new Tabs(tabsElement, tabElements, options, instanceOptions);
console.log(tabs)
  },
  methods: {
      agg_orders_from_breakdown(data){
        if (data !== undefined){
          return _.sortBy(Object.values(data).map(item => item.orders).flat(), [function(o) { return -o.submitted_at_epoch; }])
        }
        return []
      },
      isPositive(value, threshold){
        if (value >= threshold){
          return "p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50"
        }
        return "p-1.5 text-xs font-medium uppercase tracking-wider text-black-800 bg-red-400 rounded-lg bg-opacity-50"
      },
        async refreshToken(){
          var formdata = new FormData();
          formdata.append("refresh", localStorage.getItem("barpot_jwt_refresh"));

          var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/login/refresh/`, requestOptions)
            .then(response => response.json())
            .then(result => {
              localStorage.setItem("barpot_jwt_access", result.access)
            })
            .catch(error => console.log('error', error));
        },
        async getUser(){
          var requestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            }
          };

          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/user`, requestOptions)
            .then(response => response.json())
            .then(data => {
              store.userData = data
              localStorage.setItem("username", data["info"]["username"])
            })
            .catch(error => console.log('error', error));
        },
        handleSignout(){
          localStorage.removeItem("barpot_jwt_access")
          localStorage.removeItem("barpot_jwt_refresh")
          window.location = "/login"
        },
        handleAccount(){
          window.location = "/account"
        },
        handleHome(){
          window.location = "/dashboard"
        },
        promptForSignInKey() {
            var tier_key = prompt('Please enter your Tier Key (This is acquired only when you purchase a "paid tier" plan.)', "");
            if (tier_key != null) {
                localStorage.setItem("auth_tier_key", tier_key)
            }
        },
        async getPortfolio(){
            let urlBuild = new URL(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/portfolio_item_aggregate`)
            urlBuild.searchParams.set("portfolio_id", currPath.searchParams.get("portfolio_id"))
            if(currPath.searchParams.get("time_span")){
              urlBuild.searchParams.set("time_span", currPath.searchParams.get("time_span"))
            }
            let headers = {
                      'Content-Type': 'application/json',
                  }
            
            if (localStorage.getItem("barpot_jwt_access") !== undefined && localStorage.getItem("barpot_jwt_access") !== 'undefined' && localStorage.getItem("barpot_jwt_access") !== null){
              headers['Authorization'] = "Bearer " + localStorage.getItem("barpot_jwt_access")
            }

            await fetch(urlBuild.toString(), {
                method: 'GET', // or 'PUT'
                headers: headers,
                }).then(response => {
                    // if(response.status == 401 || response.status == 403){
                    //     alert("You are not Authorized to Access this Page! Please Sign up OR Sign in!")
                    //     window.location = "/login"
                    //     return null
                    // }
                    return response.json()
                })
                .then(resp_data => {
                    // cache the data to memory                    
                    store.xhrDataPortfolioItem = resp_data;
                    this.renderDonutChart()
                });
      },
      renderDonutChart(){
        const getChartOptions = () => {
          return {
            series: [store.xhrDataPortfolioItem.agg.portfolio[store.xhrDataPortfolioItem.id].trades_analysis.won_trades,
            store.xhrDataPortfolioItem.agg.portfolio[store.xhrDataPortfolioItem.id].trades_analysis.loss_trades,
            store.xhrDataPortfolioItem.agg.portfolio[store.xhrDataPortfolioItem.id].trades_analysis.open_trades],
            colors: ["#1C64F2", "#f76868", "#FDBA8C", "#E74694"],
            chart: {
              height: 300,
              width: "100%",
              type: "donut",
            },
            stroke: {
              colors: ["transparent"],
              lineCap: "",
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      show: true,
                      fontFamily: "Inter, sans-serif",
                      offsetY: 20,
                    },
                    total: {
                      showAlways: true,
                      show: true,
                      label: "W/L Ratio",
                      fontFamily: "Inter, sans-serif",
                      formatter: (w) => {
                        console.log(w)
                        // const sum = w.globals.seriesTotals.reduce((a, b) => {
                        //   return a + b
                        // }, 0)
                        return (store.xhrDataPortfolioItem.agg.portfolio[store.xhrDataPortfolioItem.id].trades_analysis.winrate).toFixed(2) + "%"
                      },
                    },
                    value: {
                      show: true,
                      fontFamily: "Inter, sans-serif",
                      offsetY: -20,
                      formatter: function (value) {
                        return value
                      },
                    },
                  },
                  size: "80%",
                },
              },
            },
            grid: {
              padding: {
                top: -2,
              },
            },
            labels: ["Wins", "Losses", "Opens"],
            dataLabels: {
              enabled: false,
            },
            legend: {
              position: window.screen.width <= 700 ? "bottom": "right",
              fontFamily: "Inter, sans-serif",
            },
            yaxis: {
              labels: {
                formatter: function (value) {
                  return value + " Trades"
                },
              },
            },
            xaxis: {
              labels: {
                formatter: function (value) {
                  return value + " Trades"
                },
              },
              axisTicks: {
                show: false,
              },
              axisBorder: {
                show: false,
              },
            },
          }
        }

        if (document.getElementById("donut-chart") && typeof ApexCharts !== 'undefined') {
          const chart = new window.ApexCharts(document.getElementById("donut-chart"), getChartOptions());
          chart.render();
        }
      },
      handleEditButton(){
        let urlBuild = new URL(`${window.location.origin}/product/portfolio_operation`);
        urlBuild.searchParams.set("portfolio_id", currPath.searchParams.get("portfolio_id"))
        urlBuild.searchParams.set("operation_environment", "portfolio_modify")
        window.location.href = urlBuild.toString()
      },
        onStockSubmit(event){
          if (event.keyCode === 13){
            window.location.href = `/stock?symbol=${event.target.value}`
          }
          
        },
        handlePreviousPage(){
          let url = new URL(window.location.href);
          let c_page = parseInt(url.searchParams.get("page_num"))
          if (c_page == null || c_page <= 0){
            c_page = 0;
          }
          url.searchParams.set('page_num', (c_page - 1));
          window.location.href = url.href;
        },
        handleNextPage(){
          let url = new URL(window.location.href);
          let c_page = parseInt(url.searchParams.get("page_num"))
          if (c_page == null || c_page <= 0 || isNaN(c_page) == true){
            c_page = 0;
          }
          url.searchParams.set('page_num', this.xhrDataCardData.pagination.next_page);
          window.location.href = url.href;
        },
        handleBeginPage(){
          let url = new URL(window.location.href);
          url.searchParams.set('page_num', 0);
          window.location.href = url.href;
        },
        handleLastPage(){
          let url = new URL(window.location.href);
          url.searchParams.set('page_num', this.xhrDataCardData.pagination.page_total);
          window.location.href = url.href;
        },
        normalizeTime(){
            console.log(this.users)
        },
        generateRandomNumber(min, max) {
            let highlightedNumber = Math.random() * (max - min) + min;
            return(highlightedNumber);
        },
        copyNavLink(){
          if (!this.$refs.publicViewingToggle?.checked){
            $toast.open({
                message: "Your link is not shareable because it doesn't have public viewing enabled, Please enable it and try again. \n (The toggle switch to the left)",
                type: 'error',
                duration: 10000,
                position: 'bottom'
                // all of other options may go here
              });
              return false
          }
          navigator.clipboard.writeText(`https://barpot.io/qlk/portfolio_instance.html` + currPath.search).then(function() {
              $toast.open({
                message: "Link Copied, Share it with the world!" + ' 🚀',
                type: 'success',
                duration: 10000,
                position: 'bottom'
                // all of other options may go here
              });
          })
        },
        async handlePaperInvestment(){
          const payloadData = {
            amount: document.getElementById("investment-input").value,
            id: currPath.searchParams.get('portfolio_id')
          }
          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/portfolio_trader/invest/paper/strategy`, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            },
            body: JSON.stringify(payloadData)
          })
          .then(response => {
            if(response.status == 401 || response.status == 500 || response.status == 404){
              $toast.open({
                message: "Failed to update Portfolio | Code: " + response.status ,
                type: 'error',
                duration: 10000,
                position: 'bottom'
                // all of other options may go here
              });
            }
            return response.json()
          })
          .then(data => {
            if (data?.code == "token_not_valid" || data?.status == "portfolio_item.user_not_owner"){
              $toast.open({
                message: "Could not complete operation you are not authorized.",
                type: 'error',
                duration: 10000,
                position: 'bottom'
                // all of other options may go here
              });
              return
            }
              $toast.open({
                message: data?.message,
                type: data?.status == "warn" ? 'warning': 'success',
                duration: 10000,
                position: 'bottom'
                // all of other options may go here
              });
          });
        },
        async updatePortfolioItemConfig(newConfigData){
          await fetch(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/auth/update_portfolio_item?portfolio_id=${currPath.searchParams.get('portfolio_id')}`, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
            },
            body: JSON.stringify(newConfigData)
          })
          .then(response => {
            if(response.status == 401 || response.status == 500 || response.status == 404){
              $toast.open({
                message: "Failed to update Portfolio | Code: " + response.status ,
                type: 'error',
                duration: 10000,
                position: 'bottom'
                // all of other options may go here
              });
            }
            return response.json()
          })
          .then(data => {
            if (data?.code == "token_not_valid" || data?.status == "portfolio_item.user_not_owner"){
              $toast.open({
                message: "Could not complete operation you are not authorized.",
                type: 'error',
                duration: 10000,
                position: 'bottom'
                // all of other options may go here
              });
              return
            }
              $toast.open({
                message: "Your PortFolio was updated successfully!",
                type: 'success',
                duration: 10000,
                position: 'bottom'
                // all of other options may go here
              });
          });
        },
        async handlePublicStatusPortfolioItem(event){
          let updatedUserParam = {user: {...store.xhrDataPortfolioItem.user, ...{is_public: Boolean(event.target.checked)}}}
          this.updatePortfolioItemConfig(updatedUserParam)
        },
        handleTableShowNestedTradeJournal(symbol){
          document.getElementById(symbol + "_table_banner").classList.toggle('hidden');
          document.getElementById(symbol + "_table_content").classList.toggle('hidden');
          document.getElementById(symbol + "_table_accordion").classList.toggle('table-accordion-active');
        }
    }
}

</script>

<style scoped>
.blur-state-loading {
  filter: blur(16px);
}

.k-line-chart-container {
  display: flex;
  flex-direction: column;
  margin: 1em 0em;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px;
  background-color: #ffffff;
  width: 95%;
  height: 45vw;
  padding: 16px 6px 16px 16px;
}

.table-accordion {
  transition: 0.4s;
}

.table-accordion:before {
  content: '+'; /* Unicode character for "plus" sign (+) */
  font-size: 13px;
  color: #777;
  float: left;
  margin-right: 5px;
}

.table-accordion-active:before {
  content: "-"; /* Unicode character for "minus" sign (-) */
}



@media (min-width: 1900px) {
  .k-line-chart-container {
    height: 45vw;
  }
}

@media (min-width: 1440px) {
  .k-line-chart-container {
    height: 45vw;
  }
}

@media (min-width: 1281px) and (max-width: 1440px) {
  .k-line-chart-container {
    height: 45vw;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .k-line-chart-container {
    height: 45vw;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .k-line-chart-container {
    height: 125vh;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .k-line-chart-container {
    height: 125vh;
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .k-line-chart-container {
    height: 125vh;
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .k-line-chart-container {
    height: 125vh;
    width: 100%;
  }
}
</style>