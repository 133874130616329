<script setup>
import {defineProps, reactive, onMounted  } from 'vue';
import moment from 'moment-timezone'
import MainChart from "./chart/MainChart.vue";
import baselineLayout from './components/baselineLayout.vue'
import baselineBreadCrumbs from './components/baselineBreadCrumbs.vue'
import HomeReportingSlider from './components/HomeReportingSlider.vue'
import OptionsTableView from './components/OptionsTableView.vue'
import bannerCouponPriceOff from './cta_actions/bannerCouponPriceOff.vue'
import nonRegUserInteractPopup from './cta_actions/nonRegUserInteractPopup.vue'
import progressBar from './components/progressBar.vue'
import _ from 'lodash'
// import {useToast} from 'vue-toast-notification';
// import 'vue-toast-notification/dist/theme-sugar.css';

// const $toast = useToast();

let currPath = new URL(window.location.href);
let passingSymbol = currPath.searchParams.get("symbol");

let paramSymbol = currPath.searchParams.get("symbol");
let paramStockSymbol = currPath.searchParams.get("symbol");
let paramTimestampFrom = currPath.searchParams.get("timestamp_from");
let paramTimestampTo = currPath.searchParams.get("timestamp_to");
let paramDiscordClientId = currPath.searchParams.get("discord_client_id");
let paramIsDev = currPath.searchParams.get("dev") == "true" ? true : false;
let paramAccessToken = currPath.searchParams.get("access_token");
let paramStockModelId = currPath.searchParams.get("stock_model_id");

const store = reactive({
      xhrDataCardDataPopular: [
        ],
      xhrDataCardDataETF: [
        ],
      xhrDataCardData: {
        signal_stream: [],
        pagination: {
          curr_page: "",
          page_total: ""
        }
      },
      xhrData: {},
      risk_indicators: {
          low: true,
          high: true,
          moderate: true
      },
      urlObject: currPath,
      userData: {
        subscription_status: "",
        username: "...",
        email: "...",
        info: {
          phone_number: ""
        }
      },
      userParams: {
        paramDiscordClientId,
        paramIsDev,
        paramStockSymbol,
        paramSymbol,
        paramTimestampFrom,
        paramTimestampTo,
        paramAccessToken,
        paramStockModelId
      },
      cta: {
        showEmailSubscribeBanner: false
      }
})
const props = defineProps(['symbol'])
console.log(props)

const isPositive = (value, threshold) => {
  if (value >= threshold){
    return "p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50"
  }
  return "p-1.5 text-xs font-medium uppercase tracking-wider text-black-800 bg-red-400 rounded-lg bg-opacity-50"
}

const markSignalBar = (precent) => {
    precent = parseFloat(precent)
    // extra-weak, weak, medium, strong, super-strong
    let css_strength = ''
    if( precent >= 0 && precent <= 21){
        css_strength = "extra-weak";
    }
    
    if( precent >= 21 && precent <= 40){
        css_strength = "weak";
    }

    if( precent >= 40 && precent <= 80){
        css_strength = "medium";
    }

    if( precent >= 80 && precent <= 90){
        css_strength = "strong";
    }

    if( precent >= 90 && precent <= 100){
        css_strength = "super-strong";
    }

    return css_strength
   }

  const populateChart = async (symbol, timestamp_from, timestamp_to, discord_client_id, access_token) => {

            let urlBuild = new URL(`${process.env.VUE_APP_BARSTOOL_UI_BACKEND_HOST}:${process.env.VUE_APP_BARSTOOL_UI_BACKEND_PORT}/api/ticker`)
            urlBuild.searchParams.set("symbol", symbol)
            urlBuild.searchParams.set("timestamp_from", timestamp_from)
            urlBuild.searchParams.set("timestamp_to", timestamp_to)
            urlBuild.searchParams.set("discord_client_id", discord_client_id)
            urlBuild.searchParams.set("access_token", access_token)
            // urlBuild.searchParams.set("stock_model_id", this.userParams.paramStockModelId)
            await fetch(urlBuild.toString(), {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("barpot_jwt_access")
                },
                body: JSON.stringify({}),
                }).then(response => {
                    if(response.status == 401 || response.status == 403){
                        alert("You are not Authorized to Access this Page! Please Sign up OR Sign in and ensure that you have the correct subscription tier.")
                        window.location = "/dashboard"
                        return null
                    }
                    return response.json()
                })
                .then(resp_data => {
                    // cache the data to memory
                    store.xhrData = resp_data;

                    const getChartOptions = () => {
                      return {
                        series: [resp_data.backtest_results.number_of_successful_trades,
                        resp_data.backtest_results.number_of_failed_trades,
                        resp_data.backtest_results.number_of_open_trades],
                        colors: ["#6AD19F", "#F98080", "#7bb3ff"],
                        chart: {
                          height: 300,
                          width: "100%",
                          type: "donut",
                        },
                        stroke: {
                          colors: ["transparent"],
                          lineCap: "",
                        },
                        plotOptions: {
                          pie: {
                            donut: {
                              labels: {
                                show: true,
                                name: {
                                  show: true,
                                  fontFamily: "Inter, sans-serif",
                                  offsetY: 20,
                                },
                                total: {
                                  showAlways: true,
                                  show: true,
                                  label: "W/L Ratio",
                                  fontFamily: "Inter, sans-serif",
                                  formatter: (w) => {
                                    console.log(w)
                                    // const sum = w.globals.seriesTotals.reduce((a, b) => {
                                    //   return a + b
                                    // }, 0)
                                    if ((resp_data.backtest_results?.number_of_successful_trades + resp_data.backtest_results?.number_of_failed_trades) < 10){
                                      return "NA"
                                    }
                                    return (resp_data.backtest_results.winrate_precent).toFixed(2) + "%"
                                  },
                                },
                                value: {
                                  show: true,
                                  fontFamily: "Inter, sans-serif",
                                  offsetY: -20,
                                  formatter: function (value) {
                                    return value
                                  },
                                },
                              },
                              size: "80%",
                            },
                          },
                        },
                        grid: {
                          padding: {
                            top: -2,
                          },
                        },
                        labels: ["Wins", "Losses", "Opens"],
                        dataLabels: {
                          enabled: false,
                        },
                        legend: {
                          position: window.screen.width <= 700 ? "bottom": "top",
                          fontFamily: "Inter, sans-serif",
                        },
                        yaxis: {
                          labels: {
                            formatter: function (value) {
                              return value + " Trades"
                            },
                          },
                        },
                        xaxis: {
                          labels: {
                            formatter: function (value) {
                              return value + " Trades"
                            },
                          },
                          axisTicks: {
                            show: false,
                          },
                          axisBorder: {
                            show: false,
                          },
                        },
                      }
                    }

                    if (document.getElementById("donut-chart") && typeof ApexCharts !== 'undefined') {
                      const chart = new window.ApexCharts(document.getElementById("donut-chart"), getChartOptions());
                      chart.render();

                      // Get all the checkboxes by their class name
                      // const checkboxes = document.querySelectorAll('#devices input[type="checkbox"]');

                      // // Function to handle the checkbox change event
                      // function handleCheckboxChange(event, chart) {
                      //     const checkbox = event.target;
                      //     if (checkbox.checked) {
                      //         switch(checkbox.value) {
                      //           case 'desktop':
                      //             chart.updateSeries([15.1, 22.5, 4.4, 8.4]);
                      //             break;
                      //           case 'tablet':
                      //             chart.updateSeries([25.1, 26.5, 1.4, 3.4]);
                      //             break;
                      //           case 'mobile':
                      //             chart.updateSeries([45.1, 27.5, 8.4, 2.4]);
                      //             break;
                      //           default:
                      //             chart.updateSeries([55.1, 28.5, 1.4, 5.4]);
                      //         }

                      //     } else {
                      //         chart.updateSeries([35.1, 23.5, 2.4, 5.4]);
                      //     }
                      // }

                      // // Attach the event listener to each checkbox
                      // checkboxes.forEach((checkbox) => {
                      //     checkbox.addEventListener('change', (event) => handleCheckboxChange(event, chart));
                      // });
                    }
                })
  }

  onMounted(() => {
    populateChart(store.userParams.paramSymbol, null, null, store.userParams.paramDiscordClientId, store.userParams.paramAccessToken);
    
    // Should we show cta banners
    setInterval(() => {
      if(window?.localStorage.getItem("username") === null){
        store.cta.showEmailSubscribeBanner = true
      } 
    }, 1000);
})
</script>

<template>
  <baselineLayout>
      <!-- Breadcrumb -->
      <baselineBreadCrumbs/>
      <div v-if="Object.keys(store.xhrData).length <= 0" class="mx-auto fixed inset-0 flex items-center z-10" id="signin-success-message">
        <div class="px-6 py-4 rounded-md text-lg flex flex-col items-center w-full">
          <div class="flex flex-col justify-center text-center items-center">
              <fingerprint-spinner
                  :animation-duration="2000"
                  :size="160"
                  color="#0051d8"
              />
          </div>
          <div id="default-carousel" class="relative w-full 2xl:w-full xl:w-full lg:w-full sm:min-w-[15rem] text-center" data-carousel="slide">
            <!-- Carousel wrapper -->
            <div class="relative overflow-hidden rounded-lg h-16">
                <!-- Item 1 -->
                <div class="hidden duration-700 ease-in-out" data-carousel-item>
                    <span class="text-gray-800">Loading your data...</span>
                </div>
                <!-- Item 2 -->
                <div class="hidden duration-700 ease-in-out" data-carousel-item>
                    <span class="text-gray-800">Counting all the kittens....</span>
                </div>
                <!-- Item 3 -->
                <div class="hidden duration-700 ease-in-out" data-carousel-item>
                    <span class="text-gray-800">There seems to be alot of em!</span>
                </div>
                <!-- Item 4 -->
                <div class="hidden duration-700 ease-in-out" data-carousel-item>
                    <span class="text-gray-800">Still Working on the final touches....</span>
                </div>
                <!-- Item 5 -->
                <div class="hidden duration-700 ease-in-out" data-carousel-item>
                    <span class="text-gray-800">Almost there....</span>
                </div>
            </div>
          </div>
          <progressBar />
          </div>
      </div>
      <div v-if="store.xhrData.status === 'portfolio_item.not_public'" class="mx-auto fixed inset-0 flex items-center z-10" id="signin-success-message">
        <div class="px-6 py-4 rounded-md text-lg flex flex-col items-center w-full">
          <div class="flex flex-col justify-center text-center items-center">
              <div class="flex items-center flex-col duration-700 ease-in-out" data-carousel-item>
                    <img src="/images/undraw/undraw_refreshing_beverage_td3r.svg" alt="" class="lg:w-2/4 mb:w-2/4 sm:w-4/5 h-24" style="background-color: transparent;">
                    <span class="text-gray-800">Hmmm, Seems that your not allowed to view this portfolio, Please request access from the creator to view.</span>
                </div>
          </div>
        </div>
      </div>
      
      <div v-if="store.xhrData.status !== 'portfolio_item.not_public'">
        <div :class=" Object.keys(store.xhrData).length <= 0 ? '2xl:ml-72 xl:ml-72 lg:ml-72 sm:ml-0 h-full p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 bg-gray-100 overflow-x-hidden blur-state-loading': '2xl:ml-72 xl:ml-72 lg:ml-72 sm:ml-0 h-full p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 bg-gray-100 overflow-x-hidden'">
            <div v-for="(warningAlert, warningAlertIdx) in store.xhrData?.agg?.portfolio[store.xhrData?.id]?.status?.items" :key="'warningAlert_' + warningAlertIdx" class="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300" role="alert">
              <span class="font-medium">Warning!</span> {{warningAlert.message}}
            </div>
            <div class="flex 2xl:flex-row xl:flex-row lg:flex-row sm:flex-col px-4 justify-between items-center">
                <div class="flex px-4 sm:px-6 items-center md:space-x-24">
                  <h1 class="hidden flex flex-col mb-4 text-md font-extrabold text-gray-900 dark:text-white md:text-lg lg:text-lg">
                    <span class="px-4 bg-yellow-100 w-fit text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300">Creator: {{store.xhrData?.user?.username}}</span>
                    <span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">{{store.xhrData?.name}}</span>
                  </h1>
                    <!-- <div v-if="store.userData.subscription_status == 'subscription.active'" class="bg-yellow-100 border border-yellow-400 text-blue-700 text-center px-2 py-1 rounded relative" role="alert">
                      <strong class="font-bold">You are a Premium Member!</strong>
                    </div>
                    <div v-if="store.userData.subscription_status == 'subscription.null'" class="flex flex-col bg-red-100 border border-yellow-400 text-blue-700 text-center px-2 py-3 rounded relative" role="alert">
                      <strong class="font-bold">You are not a Premium Member!</strong>
                      <span class="font-sm italic">Some alerts will be blurred below!</span>
                      <a href="/account" class="underline">Subscribe to a premium account here!</a>
                    </div> -->
                </div>
                
                <div class="hidden flex items-center">
                  <div class="flex items-center mx-4 rounded-md shadow-sm h-fit" role="group">
                    <button data-tooltip-target="portfolio-notification-toogle-tooltip" type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-l-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
                      <label class="relative inline-flex items-center cursor-pointer mr-4">
                        <input type="checkbox" ref="publicViewingToggle" @click="handlePublicStatusPortfolioItem($event)" value="" class="sr-only peer" :checked="store.xhrData?.user?.is_public">
                        <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                      </label>
                      Public Viewing
                    </button>
                    <div id="portfolio-notification-toogle-tooltip" role="tooltip" class="absolute z-40 w-2/6 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 opacity-0 invisible" style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(478.545px, -1328px, 0px);" data-popper-placement="top">Allow this portfolio to be shareable to other visitors and users of Barpot.<div data-v-2e83651c="" class="tooltip-arrow" data-popper-arrow="" style="position: absolute; left: 0px; transform: translate3d(58.1818px, 0px, 0px);"></div></div>
                    
                    <button @click="copyNavLink()" data-tooltip-target="portfolio-share-status-tooltip" type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border-t border-b border-gray-900 hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
                      <span class="material-symbols-outlined mr-2">share</span>
                      Share Link
                    </button>
                    <div id="portfolio-share-status-tooltip" role="tooltip" class="absolute z-40 w-2/6 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 opacity-0 invisible" style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(478.545px, -1328px, 0px);" data-popper-placement="top">A link to share your portfolio w/ others. <div data-v-2e83651c="" class="tooltip-arrow" data-popper-arrow="" style="position: absolute; left: 0px; transform: translate3d(58.1818px, 0px, 0px);"></div></div>
                    
                    <button @click="handleEditButton()" type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-r-md hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
                      <span class="material-symbols-outlined mr-2">edit</span>
                       Edit Portfolio
                    </button>
                  </div>
                </div>

            </div>

            
            
            <div class="flex space-x-8 items-center justify-center rounded dark:bg-gray-800">
              <MainChart :propData="store.xhrData" :enableDataFetch="true" :propSymbol="passingSymbol"/>
            </div>
          
            <div class="flex flex-row w-[auto] 2xl:w-[auto] xl:w-[auto] lg:w-[94%] justify-start 2xl:justify-start xl:justify-start lg:justify-center p-6 mb-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div class="flex 2xl:space-x-4 xl:space-x-0 lg:space-x-0 w-full 2xl:flex-row xl:flex-row lg:flex-col sm:flex-col">
              <div class="flex flex-row divide-x sm:mt-4">
                  <div class="flex bg-white rounded-lg shadow dark:bg-gray-800 p-2 md:p-4 2xl:flex-col xl:flex-col lg:flex-col sm:flex-col 2xl:bg-white xl:bg-white lg:shadow 2xl:shadow xl:shadow lg:shadow sm:bg-transparent sm:shadow-none h-fit">
                    
                    <div class="flex hidden justify-between mb-3">
                        <div class="flex justify-center items-center">
                            <h5 class="text-xl font-bold leading-none text-gray-900 dark:text-white pr-1">Simulated Winrate</h5>
                            <svg data-popover-target="chart-info" data-popover-placement="bottom" class="w-3.5 h-3.5 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white cursor-pointer ml-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm0 16a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm1-5.034V12a1 1 0 0 1-2 0v-1.418a1 1 0 0 1 1.038-.999 1.436 1.436 0 0 0 1.488-1.441 1.501 1.501 0 1 0-3-.116.986.986 0 0 1-1.037.961 1 1 0 0 1-.96-1.037A3.5 3.5 0 1 1 11 11.466Z"/>
                            </svg>
                            <div data-popover id="chart-info" role="tooltip" class="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400">
                                <div class="p-3 space-y-2">
                                    <h3 class="font-semibold text-gray-900 dark:text-white">Activity growth - Incremental</h3>
                                    <p>Report helps navigate cumulative growth of community activities. Ideally, the chart should have a growing trend, as stagnating chart signifies a significant decrease of community activity.</p>
                                    <h3 class="font-semibold text-gray-900 dark:text-white">Calculation</h3>
                                    <p>For each date bucket, the all-time volume of activities is calculated. This means that activities in period n contain all activities up to period n, plus the activities generated by your community in period.</p>
                                    <a href="#" class="flex items-center font-medium text-blue-600 dark:text-blue-500 dark:hover:text-blue-600 hover:text-blue-700 hover:underline">Read more <svg class="w-2 h-2 ml-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
                              </svg></a>
                                </div>
                                <div data-popper-arrow></div>
                            </div>
                          </div>
                        <div>
                          <button type="button" data-tooltip-target="data-tooltip" data-tooltip-placement="bottom" class="hidden sm:inline-flex items-center justify-center text-gray-500 w-8 h-8 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm"><svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"/>
                    </svg><span class="sr-only">Download data</span>
                          </button>
                          <div id="data-tooltip" role="tooltip" class="hidden absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                              Download CSV
                              <div class="tooltip-arrow" data-popper-arrow></div>
                          </div>
                        </div>
                    </div>

                    <div class="hidden">
                      <div class="flex" id="devices">
                        <div class="flex items-center mr-4">
                            <input id="desktop" type="checkbox" value="desktop" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="desktop" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Desktop</label>
                        </div>
                        <div class="flex items-center mr-4">
                            <input id="tablet" type="checkbox" value="tablet" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="tablet" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Tablet</label>
                        </div>
                        <div class="flex items-center mr-4">
                            <input id="mobile" type="checkbox" value="mobile" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="mobile" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Mobile</label>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-col">
                      <div class="flex flex-col px-2 w-max">
                        <a href="#" class="flex flex-row items-center" data-tooltip-target="win-rate-tooltip">
                            <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Win Rate</h5>
                            <span class="material-symbols-outlined text-sm mx-1">info</span>
                        </a>
                        <div id="win-rate-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                          The percentage of successful wins (to losses) in your current portfolio.
                          <div class="tooltip-arrow" data-popper-arrow></div>
                        </div>
                        <p class="mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white">{{(store.xhrData?.backtest_results?.number_of_successful_trades + store.xhrData?.backtest_results?.number_of_failed_trades) > 10 ? new Number(store.xhrData?.backtest_results?.winrate_precent).toFixed(2).toLocaleString() + "%": "--"}}</p>
                      </div>

                      <div class="flex flex-col px-2 w-max">
                        <a href="#" class="flex flex-row items-center" data-tooltip-target="win-rate-w-open-tooltip">
                            <div class="flex flex-col">
                              <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Win Rate</h5>
                              <h5 class="text-base tracking-tight text-gray-900 dark:text-white italic">(w/ Opens Trades)</h5>
                            </div>
                            <span class="material-symbols-outlined text-sm mx-1">info</span>
                        </a>
                        <div id="win-rate-w-open-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                          The percentage of successful wins (to losses and open trades) in your current portfolio.
                          <div class="tooltip-arrow" data-popper-arrow></div>
                        </div>
                        <p class="mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white">{{(store.xhrData?.backtest_results?.number_of_successful_trades + store.xhrData?.backtest_results?.number_of_failed_trades) > 10 ? new Number(store.xhrData?.backtest_results?.winrate_w_opens_precent).toFixed(2).toLocaleString() + "%": "--"}}</p>
                      </div>

                      <div class="flex flex-col px-2 w-max">
                        <a href="#" class="flex flex-row items-center" data-tooltip-target="total-trade-tooltip">
                            <div class="flex flex-col">
                              <h5 class="text-base tracking-tight text-gray-900 dark:text-white">Total Trades</h5>
                            </div>
                            <span class="material-symbols-outlined text-sm mx-1">info</span>
                        </a>
                        <div id="total-trade-tooltip" role="tooltip" class="absolute z-40 w-2/6 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                          The overall count of trades executed within your selected timeframe for your portfolio.
                          <div class="tooltip-arrow" data-popper-arrow></div>
                        </div>
                        <p class="mb-2 text-2xl font-medium tracking-tight text-gray-900 dark:text-white">{{ new Number(store.xhrData?.backtest_results?.number_of_trades).toLocaleString()}}</p>
                      </div>
                    </div>

                    <!-- Line Chart -->
                    <div class="h-full w-full 2xl:min-w-[15rem] xl:min-w-[15rem] lg:min-w-[15rem] sm:min-w-[15rem]" id="donut-chart"></div>
                  </div>
              </div>

              <div class="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded bg-white">
                <div class="rounded-t mb-0 px-4 py-3 border-0">
                      <div class="flex flex-wrap items-center">
                        <div class="ml-2 relative w-full max-w-full flex-grow flex-1">
                          <h3 class="font-semibold text-base text-blueGray-700">
                            Trading History
                          </h3>
                        </div>
                        <div class="hidden relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                          <button class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                            See all
                          </button>
                        </div>
                      </div>
                    </div>
              
                <div class="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded bg-white h-full">
                <div class="h-[30rem] mostly-customized-scrollbar overflow-x-auto rounded-lg shadow md:block bg-white">
                <table class="w-full">
                  <thead class="bg-gray-50 border-b-2 border-gray-200">
                  <tr>
                    <th class="w-20 p-3 text-sm font-semibold tracking-wide text-left">Strength</th>
                    <th class="p-3 text-sm font-semibold tracking-wide text-left">Status</th>
                    <!-- <th class="p-3 text-sm font-semibold tracking-wide text-left">Alert Confidence</th> -->
                    <th class="p-3 text-sm font-semibold tracking-wide text-left">Entry Date</th>
                    <th class="w-24 p-3 text-sm font-semibold tracking-wide text-left">Exit Date</th>
                    <th class="w-24 p-3 text-sm font-semibold tracking-wide text-left">Entry Price</th>
                    <th class="w-32 p-3 text-sm font-semibold tracking-wide text-left">Exit Price</th>
                    <th class="w-32 p-3 text-sm font-semibold tracking-wide text-left">Return Percent</th>
                  </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-100">

                  <tr class="bg-white" v-for="single_trade in _.sortBy(store.xhrData?.signals?.buy, [function(o) { return -o.entrytime; }])" :key="single_trade?.id">
                    <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                      <button type="button" class="inline-flex items-center p-0.5 ml-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-teal-200 hover:text-teal-900 dark:hover:bg-teal-800 dark:hover:text-teal-300" data-dismiss-target="#badge-dismiss-default" aria-label="Remove">
                      <div :class="'mx-2 signal-icon ' + markSignalBar(single_trade?.neural_model_section_confidence)">
                        <div class="signal-bar"></div>
                        <div class="signal-bar"></div>
                        <div class="signal-bar"></div>
                        <div class="signal-bar"></div>
                        <div class="signal-bar"></div>
                      </div>
                    </button>
                    </td>
                    <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                        <span v-if="single_trade?.exittime == null" class="bg-indigo-100 text-indigo-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border border-indigo-400">OPEN</span>
                        <span v-if="single_trade?.exittime !== null && single_trade?.returnpct > 0" class="bg-green-300 text-black-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border border-green-400">WIN</span>
                        <span v-if="single_trade?.exittime !== null && single_trade?.returnpct < 0" class="bg-red-300 text-black-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border border-red-400">LOSS</span>
                      </td>
                    <!-- <td class="p-3 text-sm text-gray-700 whitespace-nowrap">{{(single_trade?.neural_model_section_confidence).toFixed(2)}}%</td> -->
                    <td class="p-3 text-sm text-gray-700 whitespace-nowrap">{{moment.unix(single_trade?.entrytime).tz('GMT').format('MMMM Do YYYY, h:mm A')}}</td>
                    <td class="p-3 text-sm text-gray-700 whitespace-nowrap">{{single_trade?.exittime ? moment.unix(single_trade?.exittime).tz('GMT').format('MMMM Do YYYY, h:mm A') : ""}}
                      <span v-if="single_trade?.exittime == null" class="bg-indigo-100 text-indigo-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-indigo-400 border border-indigo-400 italic">Pending</span>
                    </td>
                    <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                      <span class="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">${{ new Number(single_trade?.entryprice).toLocaleString()}}</span>
                    </td>
                    <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                      <span class="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">{{ single_trade?.exittime ? "$" + new Number(single_trade?.exitprice).toLocaleString(): "..."}}</span>
                    </td>
                    <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                      <span :class="isPositive(single_trade?.returnpct, 3)">{{ single_trade?.exittime ? new Number(single_trade?.returnpct).toLocaleString() + "%": "..."}}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
                </div>
                </div>
              </div>
            </div>
          </div>
          
          <div v-if="store.cta.showEmailSubscribeBanner == true"  class="px-6 py-2 bg-purple-700 rounded-lg dark:bg-gray-800 mb-2 md:py-6 md:px-12 lg:py-8 lg:px-16 xl:flex xl:items-center">
            <bannerCouponPriceOff />
            <nonRegUserInteractPopup />
          </div>

          <div class="flex flex-col w-[auto] 2xl:w-[auto] xl:w-[auto] lg:w-[94%] justify-start 2xl:justify-start xl:justify-start lg:justify-center p-2 mb-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div class="text-center mb-8 md:mb-8">
              <h4 class="text-gray-800 text-2xl md:text-3xl lg:text-4xl font-bold mb-2 md:mb-4">BarPot™ recently alerted stocks</h4>
              <div class="flex slide items-center justify-center">
                      <HomeReportingSlider />
              </div>
            </div>
          </div>

          <div v-if="window?.screen?.width <= 700" class="flex flex-row w-[auto] 2xl:w-[auto] xl:w-[auto] lg:w-[94%] justify-start 2xl:justify-start xl:justify-start lg:justify-center p-6 mb-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div id="alert-additional-content-5" class="p-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-gray-800" role="alert">
              <div class="flex items-center">
                <svg class="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                </svg>
                <span class="sr-only">Info</span>
                <h3 class="text-lg font-medium text-gray-800 dark:text-gray-300 italic">Please use a desktop/laptop device for the best performance while using our platform</h3>
              </div>
            </div>
          </div>
          
          <div class="flex hidden flex-col w-[auto] 2xl:w-[auto] xl:w-[auto] lg:w-[94%] justify-start 2xl:justify-start xl:justify-start lg:justify-center p-6 mb-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <OptionsTableView :propData="store.xhrData" :propSymbol="passingSymbol"/>
          </div>

          <div class="flex flex-col w-[auto] 2xl:w-[auto] xl:w-[auto] lg:w-[94%] justify-start 2xl:justify-start xl:justify-start lg:justify-center p-6 mb-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div id="alert-additional-content-5" class="p-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-gray-800" role="alert">
              <div class="flex items-center">
                <svg class="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                </svg>
                <span class="sr-only">Info</span>
                <h3 class="text-lg font-medium text-gray-800 dark:text-gray-300">This backtesting data was generated with historical data</h3>
              </div>
              <div class="mt-2 mb-4 text-sm text-gray-800 dark:text-gray-300 italic">
                While using this portfolio/strategy in any form it may include ETFs, which includes the risk associated to trading/holding and any cost of an ETF(s) before investing you should personally perform your own due-diligence on each securities/stock(s)/ETF(s) using relevant (prospectus, information) before proceeding with any information given by our website. <a href="/disclaimer" class="flex items-center underline">
                        <h5 class="text-base tracking-tight text-gray-900 dark:text-white italic">Disclaimer</h5>
                    </a>
              </div>
            </div>
            <div v-if="(store.xhrData?.backtest_results?.number_of_successful_trades + store.xhrData?.backtest_results?.number_of_failed_trades) < 10" class="p-4 mt-4 mb-4 text-sm italic text-gray-800 rounded-lg bg-yellow-300 dark:bg-yellow-800 dark:text-white-300" role="alert">
                <span class="font-medium">Note: </span> Stocks with less than 10 historical trades will not display a winrate.
              </div>
          </div>

        </div>
      </div>
  </baselineLayout>
</template>

<style scoped>
body {
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f3f5;
}
div,
p {
  box-sizing: border-box;
}
p {
  margin: 0;
}

.signal-icon {
  height: 18px;
  width: 26px;
  /* To show you the power of flexbox! */
  display: flex;
  /* Bars should be placed left to right */
  flex-direction: row;
  /* Evenly space the bars with space in between */
  justify-content: space-between;
  /* Sink the bars to the bottom, so they go up in steps */
  align-items: baseline;
}
.signal-icon .signal-bar {
  /* 4 + 3 + 4 + 3 + 4 = 18px (as set above)
     4px per bar and 3px margins between */
  width: 4px;
  /* All bars faded by default */
  opacity: 30%;
  /* Choose a color */
  background: #615754c4;
}

/* 3 different heights for 3 different bars */
.signal-icon .signal-bar:nth-child(1) { height: 20%; }
.signal-icon .signal-bar:nth-child(2) { height: 40%; }
.signal-icon .signal-bar:nth-child(3) { height: 60%; }
.signal-icon .signal-bar:nth-child(4) { height: 80%; }
.signal-icon .signal-bar:nth-child(5) { height: 100%; }

/* Emphasize different bars depending on
   weak/medium/strong classes */
.signal-icon.extra-weak .signal-bar:nth-child(1)
{ opacity: 100%; background: #aa2200;}

.signal-icon.weak .signal-bar:nth-child(1),
.signal-icon.weak .signal-bar:nth-child(2)
{ opacity: 100%; background: #bc5008;}

.signal-icon.medium .signal-bar:nth-child(1),
.signal-icon.medium .signal-bar:nth-child(2),
.signal-icon.medium .signal-bar:nth-child(3)
{ opacity: 100%; background: #ffd966;}

.signal-icon.strong .signal-bar:nth-child(1),
.signal-icon.strong .signal-bar:nth-child(2),
.signal-icon.strong .signal-bar:nth-child(3),
.signal-icon.strong .signal-bar:nth-child(4)
{ opacity: 100%; background: #71aa00;}

.signal-icon.super-strong .signal-bar:nth-child(1),
.signal-icon.super-strong .signal-bar:nth-child(2),
.signal-icon.super-strong .signal-bar:nth-child(3),
.signal-icon.super-strong .signal-bar:nth-child(4),
.signal-icon.super-strong .signal-bar:nth-child(5)
{ opacity: 100%; background: #39aa00;}

.blur-state-loading {
  filter: blur(16px);
}
.k-line-chart-container {
  display: flex;
  flex-direction: column;
  margin: 1em 0em;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px;
  background-color: #ffffff;
  width: 95%;
  height: 45vw;
  padding: 16px 6px 16px 16px;
}

@media (min-width: 1900px) {
  .k-line-chart-container {
    height: 45vw;
  }
}

@media (min-width: 1440px) {
  .k-line-chart-container {
    height: 45vw;
  }
}

@media (min-width: 1281px) and (max-width: 1440px) {
  .k-line-chart-container {
    height: 45vw;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .k-line-chart-container {
    height: 45vw;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .k-line-chart-container {
    height: 125vh;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .k-line-chart-container {
    height: 125vh;
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .k-line-chart-container {
    height: 125vh;
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .k-line-chart-container {
    height: 125vh;
    width: 100%;
  }
}
</style>